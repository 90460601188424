// import Amplify from 'aws-amplify';
// import config from './aws-exports';
import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import ReactGA from 'react-ga';
// import { isAndroid, isIOS } from 'react-device-detect';
import AuthContextProvider from '../../_contexts/AuthContext';
import NavigationBar from '../NavigationBar';
import Footer from '../Footer';
import AppBanner from '../AppBanner';

import 'firebase/auth';

// Firebase auth: onAuthStateChanged
function onAuthStateChange(setFirebaseUser) {
  return firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (firebaseUser) {
      setFirebaseUser({ isAuth: true, email: firebaseUser.email });
    } else {
      setFirebaseUser({ isAuth: false, email: '' });
    }
  });
}
const trackingId = '277357691';
ReactGA.initialize(trackingId);
ReactGA.set({ userId: 'web' });

const App = () => {
  const [firebaseUser, setFirebaseUser] = useState({ isAuth: false });
  useEffect(() => {
    onAuthStateChange(setFirebaseUser);
  }, []);
  return (
    <AuthContextProvider>
      <div>
        {/* Replace <AddBanner /> with the isAndroid || isIOS line bellow
        to show banner only on mobile web */}
        <AppBanner />
        {/* {isAndroid || isIOS ? <AppBanner /> : null} */}
        <NavigationBar userEmail={firebaseUser ? firebaseUser.email : null} />
        <Footer />
      </div>
    </AuthContextProvider>
  );
};

export default App;
