/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import useCommunityPosts from '../../_hooks/community_posts';
import CreatePostButton from '../Buttons/CreatePostButton';
import PostList from './PostList';
import './style.css';

const CommunityPage = () => {
  const [category, setCategory] = useState('all');
  let posts = useCommunityPosts();
  if (posts && category !== 'all') {
    posts = posts.filter((item) => {
      return item.type === category;
    });
  }
  if (posts) {
    return (
      <div className="community-component">
        <CreatePostButton />
        <div className="discussions-main-container">
          <div className="row">
            <div className="col-xs-12 col-md-3 community-categories">
              <div className="main-container">
                <div className="community-list-title profile-container">
                  <h1 className="page-title">Community</h1>
                  <h2>Categories</h2>
                  <div>
                    <button
                      type="button"
                      className={`${category === 'all' ? 'btn btn-primary' : 'btn btn-default'} all-post-categories`}
                      onClick={() => {
                        setCategory('all');
                      }}
                    >
                      <img className="button-image" width="22" alt="" src="../a/i/globe_white.svg" />
                      All Posts
                    </button>
                    <button
                      type="button"
                      className={`${category === 'recommendation' ? 'btn btn-primary' : 'btn btn-default btn-cat-recs'} all-post-categories`}
                      onClick={() => {
                        setCategory('recommendation');
                      }}
                    >
                      <img className="button-image" width="22" alt="" src="../a/i/thumbs_up_black.svg" />
                      Recommendations
                    </button>
                    <button
                      type="button"
                      className={`${category === 'ask' ? 'btn btn-primary' : 'btn btn-default'} all-post-categories`}
                      onClick={() => {
                        setCategory('ask');
                      }}
                    >
                      <img className="button-image" width="22" alt="" src="../a/i/message_black.svg" />
                      Ask
                    </button>
                    <button
                      type="button"
                      className={`${category === 'other' ? 'btn btn-primary' : 'btn btn-default btn-cat-other'} all-post-categories`}
                      onClick={() => {
                        setCategory('other');
                      }}
                    >
                      <img className="button-image" width="22" alt="" src="../a/i/hash_black.svg" />
                      Other
                    </button>
                  </div>
                </div>
              </div>
              {/* <CategoryList category={category} setCategory={setCategory} /> */}
            </div>
            <div className="col-xs-12 col-md-8 community-discussions">
              <h1 className="page-title">Community</h1>
              <PostList posts={posts} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default CommunityPage;
