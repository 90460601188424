/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [dynamicLink, setDynamicLink] = useState('');
  const putDynamicLink = (dynamicLink) => {
    setDynamicLink(dynamicLink);
  };
  const [user, setUser] = useState({
    email: '',
    username: null,
    token: '',
    firstName: '',
    id: null,
    isAuth: false,
    lastName: '',
    bio: '',
    photo: '',
    onboardingCompleted: false,
    socialMedia: {
      instagram: '',
      youtube: '',
      twitter: '',
      website: '',
    },
    following: 0,
    followers: 0,
    booksRead: 0,
  });
  const [publicUser, setPublicUser] = useState({
    email: '',
    username: null,
    token: '',
    firstName: '',
    id: null,
    isAuth: false,
    lastName: '',
    bio: '',
    photo: '',
    onboardingCompleted: false,
    socialMedia: {
      instagram: '',
      youtube: '',
      twitter: '',
      website: '',
    },
    following: 0,
    followers: 0,
    booksRead: 0,
  });
  const setUserNull = () => {
    setUser({
      id: null,
      username: null,
      isAuth: false,
      email: '',
      firstName: '',
      lastName: '',
      bio: '',
      photo: '',
      onboardingCompleted: false,
      socialMedia: {
        instagram: '',
        youtube: '',
        twitter: '',
        website: '',
      },
      following: 0,
      followers: 0,
      booksRead: 0,
    });
  };
  const putUser = (user) => {
    sessionStorage.session = JSON.stringify(user.user.id);
    setUser({
      email: user.user.email,
      username: user.user.username,
      token: user.token,
      firstName: user.user.first_name,
      id: user.user.id,
      isAuth: true,
      lastName: user.user.last_name,
      bio: user.user.bio ? user.user.bio : '',
      photo: user.user.photo,
      onboardingCompleted: user.user.onboarding_completed,
      socialMedia: {
        instagram: user.user.social_media ? user.user.social_media.instagram : '',
        youtube: user.user.social_media ? user.user.social_media.youtube : '',
        twitter: user.user.social_media ? user.user.social_media.twitter : '',
        website: user.user.social_media ? user.user.social_media.website : '',
      },
      following: user.user.following,
      followers: user.user.followers,
      booksRead: user.user.books_read,
    });
  };
  const putPublicUser = (user) => {
    setPublicUser({
      email: user.user.email,
      username: user.user.username,
      token: user.token,
      firstName: user.user.first_name,
      id: user.user.id,
      isAuth: true,
      lastName: user.user.last_name,
      bio: user.user.bio ? user.user.bio : '',
      photo: user.user.photo,
      onboardingCompleted: user.user.onboarding_completed,
      socialMedia: {
        instagram: user.user.social_media ? user.user.social_media.instagram : '',
        youtube: user.user.social_media ? user.user.social_media.youtube : '',
        twitter: user.user.social_media ? user.user.social_media.twitter : '',
        website: user.user.social_media ? user.user.social_media.website : '',
      },
      following: user.user.following,
      followers: user.user.followers,
      booksRead: user.user.books_read,
    });
  };
  const [bookLists, setBookLists] = useState();
  const [page, setPage] = useState(2);
  const putBookLists = (lists, type, newBooks) => {
    const items = [];
    lists.map((list) => (
      items.push({
        id: list.id,
        title: list.title,
        description: list.description,
        type: list.type,
        booksCount: list.booksCount,
        books: list.type === type ? newBooks : list.books,
      })
    ));
    setBookLists(items);
    const page = newBooks ? newBooks.length / 10 : 1;
    setPage(page + 1);
  };
  const [publicBookLists, setPublicBookLists] = useState();
  const putPublicBookLists = (lists) => {
    const items = [];
    lists.map((list) => (
      items.push({
        id: list.id,
        title: list.title,
        description: list.description,
        type: list.type,
        booksCount: list.booksCount,
        books: list.books,
      })
    ));
    setPublicBookLists(items);
  };
  return (
    <AuthContext.Provider value={{
      user,
      page,
      publicUser,
      putUser,
      putPublicUser,
      setUserNull,
      setPage,
      bookLists,
      setBookLists,
      publicBookLists,
      putPublicBookLists,
      putBookLists,
      dynamicLink,
      setDynamicLink,
      putDynamicLink,
    }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
