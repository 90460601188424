/* eslint-disable react/prop-types */
import React from 'react';
// import '../../../style.css';

function convertUnicode(input) {
  return input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
    const charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
}

function Reply(props) {
  const { item } = props;
  return (
    <div className="reply-container">
      <div className="reply-post-photo">
        <a href={`/reader/${item.username}`}>
          <img className="user-photo" alt="reader" width="60" height="60" src={item.photo} />
        </a>
      </div>
      <div className="reply-post-text">
        <h1 className="user-name">{convertUnicode(item.first_name)}</h1>
        <p className="user-reply-post">{convertUnicode(item.message)}</p>
      </div>
    </div>
  );
}

export default Reply;
