import { useEffect, useContext } from 'react';
import elements from '../_components/BookElementList/mock_data';
import { BookContext } from '../_contexts/BookContext';
import BookSlothAPI from '../_utils/api';

const getBookElements = async (bookId, putBookElements) => {
  const api = new BookSlothAPI();
  const result = await api.getBookElements(bookId);
  if (result) {
    putBookElements(result);
  }
};

const useBookElements = (bookId) => {
  const { bookElements, putBookElements } = useContext(BookContext);
  useEffect(() => {
    if (bookElements === null) {
      getBookElements(bookId, putBookElements);
    }
  });

  return (process.env.NODE_ENV === 'staging') ? bookElements || elements : bookElements;
};

export default useBookElements;
