/* eslint-disable react/prop-types */
import React from 'react';
import './style.css';

const getStarElements = (starCount) => {
  const starItems = Array.from(Array(Math.floor(starCount)), (e, i) => {
    return <img className="star-icons" alt="rating" key={i + Math.random()} width="15" height="15" src="../a/i/1-star.png" />;
  });

  if (starItems.length < 6 && starCount % 1) {
    starItems.push(<img className="star-icons" key={starItems.length + 1 + Math.random()} alt="rating" width="15" height="15" src="../a/i/2-star.png" />);
  }

  if (starItems.length < 6) {
    for (let i = starItems.length; i < 5; i += 1) {
      starItems.push(<img className="star-icons" key={i + Math.random()} alt="rating" width="15" height="15" src="../a/i/0-star.png" />);
    }
  }

  return starItems;
};

function BookRating(props) {
  const { starRating } = props;

  const starItems = getStarElements(starRating);

  if (starRating > 0) {
    return (
      <div className="star-ratings">
        <p>
          {starItems}
          <span>
            {starRating}
          </span>
        </p>
      </div>
    );
  }
  return <div className="star-ratings" />;
}

export default BookRating;
