/* eslint-disable react/prop-types */
import React from 'react';
import parser from 'html-react-parser';
import { Modal, ModalManager, Effect } from 'react-dynamic-modal';
import '../../../_helpers/css/main.css';
// import useBookDetails from '../../../_hooks/book_details';
import './style.css';

const ReadMore = (props) => {
  const { book } = props;
  return (
    <Modal
      onRequestClose={false}
      effect={Effect.ScaleUp}
      className="react-dynamic-modal"
      // style={{ overflow: 'scroll' }}
    >
      <div id="read-more" tabIndex="-1" role="dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={ModalManager.close}
            >
              <img src="../a/i/modal_close.png" alt="" />
            </button>
          </div>
          <div className="modal-body read-more">
            <div className="row">
              <div className="col-2">
                <img src={book.cover_photo} alt="books" />
              </div>
              <div className="col-10">
                <h1 className="modal-title" id="exampleModalLabel">{book.title}</h1>
                {/* <div className="book-author-names">
                  {bookDetails.authors.map((author, index) => (
                    <h3 key={author.id}>
                      {author.first_name}
                      {' '}
                      {author.last_name}
                      {index + 1 !== bookDetails.authors.length ? ', ' : ''}
                      {' '}
                    </h3>
                  ))}
                </div> */}
              </div>
            </div>
            <p>
              {parser(book.description)}
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default ReadMore;
