/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './style.css';

const FooterSection = () => {
  return (
    <div>
      <div className="footer-like-container">
        <div className="footer-company-container">
          <div className="Footer-Company-Section row">
            <div className="col-xs-12 col-md-6">
              <a href="/">
                <img className="Footer-Logo" alt="booksloth" src="../a/i/booksloth_logo_b_01.png" />
              </a>
              <a href="https://itunes.apple.com/ng/app/booksloth/id1399024480?mt=8&ign-mpt=uo%3D2" target="_blank" rel="noreferrer">
                <img className="app-store" src="a/i/app-store.png" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.booksloth.android" target="_blank" rel="noreferrer">
                <img className="app-store google" src="a/i/play-store.png" />
              </a>
            </div>
            <div className="col-xs-12 col-md-3">
              <h2>Company</h2>
              <a href="https://www.booksloth.com/" className="Footer-Company-Section-Text">About Us</a>
              <a href="https://booksloth.com/bookclubs" className="Footer-Company-Section-Text" target="_blank" rel="noreferrer">Book Clubs</a>
              <a href="https://www.redbubble.com/people/Bookslothapp/shop" className="Footer-Company-Section-Text" target="_blank" rel="noreferrer">Store</a>
              <a href="https://medium.com/@booksloth" className="Footer-Company-Section-Text">Blog</a>
            </div>
            <div className="col-xs-12 col-md-3 download-icons">
              <h2>Follow Us</h2>
              <a href="https://www.facebook.com/booksloth/" className="Footer-Company-Section-Text">Facebook</a>
              <a href="https://www.instagram.com/bookslothapp" className="Footer-Company-Section-Text">Instagram</a>
              <a href="https://www.twitter.com/bookslothapp" className="Footer-Company-Section-Text">Twitter</a>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer-Legal-Section">
        <div className="main-container">
          <div className="Footer-Legal-Section-Text">
            <p>© 2018 BookSloth, Inc All rights reserved.</p>
            <div className="footer-legal-terms float-xs-none float-md-right">
              <a href="https://www.booksloth.com/terms/">Terms of use</a>
              <p>|</p>
              <a href="https://www.booksloth.com/privacy/">Privacy</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
