/* eslint-disable max-len */
import React from 'react';
import { ModalManager } from 'react-dynamic-modal';
import { logEvent, actionEvent, locationEvent } from '../../../_utils/analytics';
import DownloadModal from '../../DownloadModal';
import '../../ProfilePage/ProfileBio/style.css';

// function CreatePostModal() {
//   return (
//     <div className="modal fade" id="create-post" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//       <div className="modal-dialog modal-dialog-centered">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title" id="exampleModalLabel">Create Post</h5>
//             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//           </div>
//           {/* <div className="modal-body">
//               <img className="user-photo" width="60" height="60" src="../a/i/lincy.png"/>
//               <span className="textarea" role="textbox" contenteditable></span>
//               <div className="post-character-count">0/200</div>
//             </div>
//             <div className="modal-footer">
//               <div className="post-details">
//               <p>Add tag:</p>
//               <p>
//                 <button type="button" className="main-post-type btn btn-default active">#Recommendation</button>
//                 <button type="button" className="main-post-type btn btn-default active">#Ask</button>
//                 <button type="button" className="main-post-type btn btn-default active">#Challenge</button>
//               </p>
//             </div>
//               <button type="button" className="btn btn-primary">Submit</button>
//             </div> */}
//         </div>
//       </div>
//     </div>
//   );
// }

const openCreatePostModal = () => {
  // Analytics
  logEvent(null, null, actionEvent.createPost, locationEvent.community);
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
};

function CreatePostButton() {
  // const { item } = props;
  return (
    <div className="discussions-create-post">
      <button
        type="button"
        className="btn btn-main"
        data-bs-toggle="modal"
        data-bs-target="#create-post"
        onClick={openCreatePostModal.bind(this)}
      >
        Create Post
      </button>
    </div>
  );
}

export default CreatePostButton;
