/* eslint-disable react/prop-types */
import React from 'react';
import convertUnicode from '../../_utils/emoji_converter';
import './style.css';
import BookRating from '../BookRating';

function BookCommentItem(props) {
  const { comment } = props;

  return (
    <div className="row review-container">
      <div className="col-2 main-review-photo">
        <a href={`/reader/${comment.username}`}>
          <img className="user-photo" alt="reader" width="60" height="60" src={comment.photo ? comment.photo : 'https://booksloth-profile-assets.s3.amazonaws.com/profile_placeholder_3.jpeg'} />
        </a>
      </div>
      <div className="col-xs-12 col-md-10 main-review-text">
        {/* <div className="review-expand">
          <img className="post-action" alt="" width="15" height="20" src="../a/i/c-chevron.png" />
        </div> */}
        <h1 className="user-name">
          {convertUnicode(comment.first_name)}
          {' '}
          {convertUnicode(comment.last_name)}
        </h1>
        <p className="user-review">{convertUnicode(comment.review)}</p>
        <BookRating starRating={comment.star_rating} />
      </div>
    </div>
  );
}

export default BookCommentItem;
