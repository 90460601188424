import axios from 'axios';

// current year
const year = new Date().getFullYear();
const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = process.env.REACT_APP_API_TOKEN;

// Default Values
const request = {
  method: 'GET',
  headers: { 'x-api-key': `${token}` },
};

const getUserTopBadges = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/badges?top=3`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    return null;
  });
};

const getUserBadgesGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/badges?top=6`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    return null;
  });
};

const getUserTopBooks = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/books?top=10`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    return null;
  });
};

const getUserTopAuthors = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/authors?top=5`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    return null;
  });
};

const getUserTopGenres = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/genres?top=3`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    // TODO: - Error Handling
    return null;
  });
};

const getUserGenresGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/genres?top=6`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    // TODO: - Error Handling
    return null;
  });
};

const getTopBooks = async () => {
  request.url = `${BASE_URL}/yearinreview/${year}/books?top=10`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    return null;
  });
};

const getTopAuthors = async () => {
  request.url = `${BASE_URL}/yearinreview/${year}/authors?top=3`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    return null;
  });
};

const getUserStats = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/stats`;
  return axios(request).then((result) => {
    return result.data.userResult;
  }).catch(() => {
    return null;
  });
};

const getUserRatingsGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/graphs/rating`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    // TODO: - Error Handling
    return null;
  });
};

const getUserPagesGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/graphs/pages`;
  return axios(request).then((result) => {
    return result.data;
  }).catch(() => {
    // TODO: - Error Handling
    return null;
  });
};

const getDynamicLink = async (username) => {
  const req = {
    method: 'POST',
    url: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDYenFTGPoWIY9JKWxg8ZSaVyBB_ywUPQk',
    data: {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://booksloth.page.link',
        link: `https://beta.booksloth.com/reader/${username}`,
        socialMetaTagInfo: {
          socialTitle: 'My BookSloth Profile',
          socialDescription: 'My BookSloth Profile',
          socialImageLink:
              'https://s3.amazonaws.com/year-in-review.booksloth.com/background_image.png',
        },
      },
      suffix: {
        option: 'SHORT',
      },
    },
  };
  return axios(req).then((result) => {
    return result.data.shortLink;
  }).catch(() => {
    return null;
  });
};

export {
  getTopBooks,
  getTopAuthors,
  getUserTopAuthors,
  getUserTopBadges,
  getUserBadgesGraph,
  getUserTopBooks,
  getUserTopGenres,
  getUserGenresGraph,
  getUserPagesGraph,
  getUserRatingsGraph,
  getUserStats,
  getDynamicLink,
};
