/* eslint-disable import/extensions */
/* eslint-disable import/named */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext } from 'react';
import GraphItem from './GraphItem';
import { AuthContext } from '../../../../_contexts/AuthContext';
import { YearReviewContext } from '../../../../_contexts/YearReviewContext';
import {
  getUserBadgesGraph,
  getUserGenresGraph,
  getUserRatingsGraph,
  getUserPagesGraph,
} from '../../../../_utils/yearinreview';
import './style.css';

const getBadgesGraph = async (userId, putBadgesGraph) => {
  const result = await getUserBadgesGraph(userId);
  if (result) {
    putBadgesGraph(result);
  }
};
const getGenresGraph = async (userId, putGenresGraph) => {
  const result = await getUserGenresGraph(userId);
  if (result) {
    putGenresGraph(result);
  }
};
const getRatingsGraph = async (userId, putRatingsGraph) => {
  const result = await getUserRatingsGraph(userId);
  if (result) {
    putRatingsGraph(result);
  }
};
const getPagesGraph = async (userId, putPagesGraph) => {
  const result = await getUserPagesGraph(userId);
  if (result) {
    putPagesGraph(result);
  }
};

const GraphsSection = () => {
  const { user } = useContext(AuthContext);
  const {
    badgesGraph, putBadgesGraph,
    genresGraph, putGenresGraph,
    pagesGraph, putPagesGraph,
    ratingsGraph, putRatingsGraph,
  } = useContext(YearReviewContext);

  useEffect(() => {
    if (user.id && !badgesGraph) {
      getBadgesGraph(user.id, putBadgesGraph);
    }
    if (user.id && !genresGraph) {
      getGenresGraph(user.id, putGenresGraph);
    }
    if (user.id && !ratingsGraph) {
      getRatingsGraph(user.id, putRatingsGraph);
    }
    if (user.id && !pagesGraph) {
      getPagesGraph(user.id, putPagesGraph);
    }
    // eslint-disable-next-line
  }, [user.id]);

  return (
    <div className="Statistics-Section-Macro">
      <div className="main-container">
        {/* <div className="Statistics-Section-Header">
          Your year in books
        </div> */}
        <div className="Statistics-Text-Caption">
          Even as your reading habits or preferences might change, your love for books remains.
          <br />
          Here's a look at your top stats.
        </div>
        <div className="row year-books">
          <div className="col-xs-12 col-md-6">
            <h2>Top Genres</h2>
            <div className="row legend">
              <div className="col-xs-12 col-md-8">
                {genresGraph ? <GraphItem item={genresGraph} /> : null}
              </div>
              <div className="col-xs-12 col-md-4 legend-items">
                {genresGraph ? genresGraph.map((genre, index) => (
                  <div className="legend-item" key={index}>
                    <p>
                      <span
                        className="legend-circle"
                        style={{ backgroundColor: `${genre.color}` }}
                      />
                      {genre.title}
                    </p>
                  </div>
                )) : null}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <h2>Top Badges</h2>
            <div className="row legend">
              <div className="col-xs-12 col-md-8">
                {badgesGraph ? <GraphItem item={badgesGraph} /> : null}
              </div>
              <div className="col-xs-12 col-md-4 legend-items">
                {badgesGraph
                  ? badgesGraph.map((badge, index) => (
                    <div className="legend-item" key={index}>
                      <p>
                        <span
                          className="legend-circle"
                          style={{ backgroundColor: `${badge.color}` }}
                        />
                        {badge.title}
                      </p>
                    </div>
                  )) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row year-books">
          <div className="col-xs-12 col-md-6">
            <h2>Your Star Rating</h2>
            <div className="row legend">
              <div className="col-xs-12 col-md-8">
                {ratingsGraph ? <GraphItem item={ratingsGraph} /> : null}
              </div>
              <div className="col-xs-12 col-md-4 legend-items">
                {ratingsGraph ? ratingsGraph.map((ratings, index) => (
                  <div className="legend-item" key={index}>
                    <p>
                      <span
                        className="legend-circle"
                        style={{ backgroundColor: `${ratings.color}` }}
                      />
                      {ratings.title === 1 ? `${ratings.title} star` : `${ratings.title} stars`}
                    </p>
                  </div>
                )) : null}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <h2>Pages Read</h2>
            <div className="row legend">
              <div className="col-xs-12 col-md-8">
                {pagesGraph ? <GraphItem item={pagesGraph} /> : null}
              </div>
              <div className="col-xs-12 col-md-4 legend-items">
                {pagesGraph ? pagesGraph.map((pages, index) => (
                  <div className="legend-item" key={index}>
                    <p>
                      <span
                        className="legend-circle"
                        style={{ backgroundColor: `${pages.color}` }}
                      />
                      {pages.title}
                      {' '}
                      pages
                    </p>
                  </div>
                )) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphsSection;
