import React from 'react';
import BookInfoItem from '../BookInfoItem';
import BookReviews from '../BookReviews';
import './style.css';

const BookDetailsPage = () => {
  return (
    <div className="book-detail-component">
      <BookInfoItem />
      <BookReviews />
    </div>
  );
};

export default BookDetailsPage;
