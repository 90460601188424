import { useEffect, useContext } from 'react';
import comments from '../_components/BookCommentList/mock_data';
import { BookContext } from '../_contexts/BookContext';
import BookSlothAPI from '../_utils/api';

require('dotenv').config();

const getBookComments = async (bookId, putBookComments) => {
  const api = new BookSlothAPI();
  const result = await api.getBookComments(bookId);
  if (result) {
    putBookComments(result);
  }
};

const useBookComments = (bookId) => {
  const { bookComments, putBookComments } = useContext(BookContext);
  useEffect(() => {
    if (bookComments === null) {
      getBookComments(bookId, putBookComments);
    }
  });

  return (process.env.NODE_ENV === 'staging') ? bookComments || comments : bookComments;
};

export default useBookComments;
