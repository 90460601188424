/* eslint-disable react/prop-types */
import React from 'react';

function BookElementItem(props) {
  const { element } = props;
  return (
    <div className="col-xs-4 col-md-2">
      <img className="star-icons" alt="" width="50" height="50" src={element.icon} />
      <div className="notification number">{element.count}</div>
      <p>{element.name}</p>
    </div>
  );
}

export default BookElementItem;
