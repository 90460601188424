import { useEffect, useContext } from 'react';
import discoverLists from '../_components/DiscoverPage/cached';
import { DiscoverContext } from '../_contexts/DiscoverContext';
import BookSlothAPI from '../_utils/api';

const getCuratedLists = async (putCuratedLists) => {
  const api = new BookSlothAPI();
  const result = await api.getCuratedLists();
  if (result) {
    putCuratedLists(result);
  }
};

const useCuratedLists = () => {
  const { curatedLists, setCuratedLists } = useContext(DiscoverContext);

  useEffect(() => {
    if (!curatedLists) {
      getCuratedLists(setCuratedLists);
    }
  });

  return curatedLists || discoverLists;
};

export default useCuratedLists;
