/* eslint-disable react/button-has-type */
import React, { useContext } from 'react';
import { CommunityContext } from '../../../_contexts/CommunityContext';
import BookSlothAPI from '../../../_utils/api';
import PostItem from '../PostItem';
import './style.css';

// import useCommunityPosts from '../../../_hooks/community_posts';

const loadMorePosts = async (page, posts, putPosts) => {
  const api = new BookSlothAPI();
  const result = await api.getCommunityPosts(page);
  if (result) {
    putPosts(result, posts);
  }
};

const PostList = (props) => {
  const posts = props;
  const { page, putPosts } = useContext(CommunityContext);
  if (posts.posts) {
    return (
      <div className="discussion-threads-list">
        {posts.posts.map((post) => (
          <PostItem post={post} key={post.id} />
        ))}
        <div className="load-more">
          <button
            onClick={() => loadMorePosts(page, posts, putPosts)}
          >
            Read More
          </button>
        </div>
      </div>
    );
  }
  return null;
};
export default PostList;
