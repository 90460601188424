/* eslint-disable no-nested-ternary */
// import '../../_helpers/bootstrap.min.css';
import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../_contexts/AuthContext';
// import YearReviewContextProvider from '../../_contexts/YearReviewContext';
import BookSlothAPI from '../../_utils/api';
import PublicProfileBio from './PublicProfileBio';
import PublicUserBookList from './PublicUserBookList';
import BookImportButton from '../Buttons/BookImportButton';
import ReadingStatsButton from '../Buttons/ReadingStatsButton';
// import ReadingStats from './ReadingStats';
import '../ProfilePage/style.css';

const getUser = async (username, putPublicUser) => {
  const api = new BookSlothAPI();
  const result = await api.getUser(username);
  if (result) {
    putPublicUser(result);
  }
};

const PublicProfilePage = () => {
  const { username } = useParams();
  const { publicUser, putPublicUser } = useContext(AuthContext);
  // const [showStats, setShowStats] = useState(false);
  useEffect(() => {
    if (username && !publicUser.id) {
      getUser(username, putPublicUser);
    }
    // eslint-disable-next-line
	});
  return (
    <div className="profile-component">
      <div className="profile-info">
        <div className="cover-photo-container">
          <img className="profile-cover-photo" alt="bookshelf cover img" src="../../a/i/profile-cover-img.png" />
        </div>
        <PublicProfileBio />
      </div>
      <div className="profile-booklists">
        <div className="booklists-actions">
          <BookImportButton />
          <ReadingStatsButton />
          {/* <button
            type="button"
            className="btn btn-primary profile-stats"
            onClick={() => setShowStats(!showStats)}
          >
            {showStats ? 'Your Library' : 'Reading Stats'}
          </button> */}
        </div>
        {/* {showStats
          ? (
            <YearReviewContextProvider>
              <ReadingStats />
            </YearReviewContextProvider>
          )
          : publicUser.id ? <PublicUserBookList /> : null} */}
        { publicUser.id ? <PublicUserBookList /> : null}
      </div>
    </div>
  );
};

export default PublicProfilePage;
