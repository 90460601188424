import { useEffect, useContext } from 'react';
import bookDetails from '../_components/BookInfoItem/cached';
import { BookContext } from '../_contexts/BookContext';
import BookSlothAPI from '../_utils/api';

require('dotenv').config();

const getBookDetails = async (bookId, setBook) => {
  const api = new BookSlothAPI();
  const result = await api.getBookInfo(bookId);
  if (result) {
    setBook(result);
  }
};

const useBookDetails = (bookId) => {
  const { book, setBook } = useContext(BookContext);
  useEffect(() => {
    if (!book) {
      getBookDetails(bookId, setBook);
    }
  });

  return (process.env.NODE_ENV === 'staging') ? book || bookDetails : book;
};

export default useBookDetails;
