/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react';
import { Modal, ModalManager, Effect } from 'react-dynamic-modal';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import ModalEmail from '../ModalEmail';
import { firebase } from '../../../_helpers/firebase/config';

const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/profile',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // signInFailure callback must be provided to handle merge conflicts which
    // occur when an existing credential is linked to an anonymous user.
    signInFailure(error) {
      // For merge conflicts, the error.code will be
      // 'firebaseui/anonymous-upgrade-merge-conflict'.
      if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
        return Promise.resolve();
      }
      // The credential the user tried to sign in with.
      const cred = error.credential;
      // Copy data from anonymous user to permanent user and delete anonymous
      // user.
      // ...
      // Finish sign-in after data is copied.
      return firebase.auth().signInWithCredential(cred);
    },
  },
};

const openLoginWithEmail = () => {
  ModalManager.open(
    <ModalEmail onRequestClose={() => true} />,
  );
};

class ModalLogin extends Component {
  render() {
    const { onRequestClose } = this.props;
    return (
      <div className="year-hero-container">
        <div className="main-container">
          <div className="Year">
            <Modal onRequestClose={onRequestClose} effect={Effect.ScaleUp}>
              <div id="loginModal" tabIndex="-1" role="dialog">
                <div className="login">
                  <div className="modal-content">
                    <div className="modal-body">
                      <h2 className="modal-title">Welcome Back</h2>
                      <p>
                        Login and take a look back at stories you've read
                        <br />
                        {' '}
                        and the books you've lived.
                      </p>
                      <StyledFirebaseAuth
                        uiConfig={uiConfig}
                        firebaseAuth={firebase.auth()}
                      />
                      {/* <button
                        onClick={loginWithGmail.bind(this)}
                      >Login with gmail</button>
                      <button
                        onClick={loginWithFacebook.bind(this)}
                      >Login with facebook</button> */}
                      <button
                        type="submit"
                        data-toggle="modal"
                        data-target="#loginEmail"
                        className="login"
                        onClick={openLoginWithEmail.bind(this)}
                      >
                        Login with Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalLogin;
