/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import firebase from 'firebase/app';
import { Modal, Effect } from 'react-dynamic-modal';
import 'firebase/auth';
import '../../../_helpers/css/main.css';

const ModalEmail = () => {
  return (
    <div className="year-hero-container">
      <div className="main-container">
        <div className="Year">
          <Modal onRequestClose={false} effect={Effect.ScaleUp}>
            <div id="loginEmail" tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-body">
                    <h2 className="modal-title">Email Login</h2>
                    <SignInForm />
                    {/* <PasswordForgetLink />
                    <SignUpLink /> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;
    firebase.auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(updateByPropertyName('email', email));
        document.location.assign('/profile');
      })
      .catch((error) => {
        this.setState(updateByPropertyName('error', error));
      });

    event.preventDefault();
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <input
          value={email}
          onChange={(event) => this.setState(updateByPropertyName('email', event.target.value))}
          type="text"
          placeholder="Email Address"
        />
        <input
          value={password}
          onChange={(event) => this.setState(updateByPropertyName('password', event.target.value))}
          type="password"
          placeholder="Password"
        />
        <button
          disabled={isInvalid}
          type="button"
          onClick={this.onSubmit}
          className="login"
          data-dismiss="modal"
        >
          Sign In
        </button>

        {error && <p>{error.message}</p>}
      </form>
    );
  }
}

export default ModalEmail;
