/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../../../_contexts/AuthContext';
import { YearReviewContext } from '../../../../_contexts/YearReviewContext';
import { getUserTopGenres } from '../../../../_utils/yearinreview';
// import '../../../../_helpers/bootstrap.min.css';

const getGenres = async (userId, putGenres) => {
  const result = await getUserTopGenres(userId);
  if (result) {
    putGenres(result);
  }
};

const TopGenresSection = () => {
  const { user } = useContext(AuthContext);
  const { genres, putGenres } = useContext(YearReviewContext);
  useEffect(() => {
    if (user.id && !genres) {
      getGenres(user.id, putGenres);
    }
  // eslint-disable-next-line
  }, [user.id]);

  if (genres && genres.length > 0) {
    return (
      <div className="steps top-genres">
        <div className="main-container">
          <h2>
            {user.firstName ?? user.email}
            's Top Genres
          </h2>
          <h3>
            Let's see what type of BookSloth reader
            {' '}
            {user.firstName ?? user.email}
            {' '}
            is.
            <br />
            Here are the top genres
            {user.firstName ?? user.email}
            {' '}
            reads.
          </h3>
          <div className="row">
            {genres.map((genre, index) => (
              <div className="col-xs-12 col-md-4" key={index}>
                <div className="top-genre">
                  <h1 className="number">{genre.genres_count}</h1>
                  <h2>{genre.name}</h2>
                  <img className="icon" src={genre.image} alt="genre" />
                  <p>{genre.message}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default TopGenresSection;
