import React from 'react';
import { ModalManager } from 'react-dynamic-modal';
import { logEvent, actionEvent, locationEvent } from '../../../_utils/analytics';
// import YearInReview from '../../YearInReview';
import DownloadModal from '../../DownloadModal';

const openReadingStatsModal = () => {
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
  // Analytics
  logEvent(null, null, actionEvent.readingStats, locationEvent.profile);
};

function ReadingStatsButton() {
  return (
    <button
      type="button"
      className="btn btn-primary profile-stats"
      onClick={() => openReadingStatsModal()}
    >
      Reading Stats
    </button>
  );
}

export default ReadingStatsButton;
