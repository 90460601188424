/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useContext } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import firebase from 'firebase/app';
import { ModalManager } from 'react-dynamic-modal';
import { Navbar, Nav } from 'react-bootstrap';
import ModalLogin from '../Auth/ModalLogin';
import BookSlothAPI from '../../_utils/api';
import BookContextProvider from '../../_contexts/BookContext';
import BookDetailsPage from '../BookDetailsPage';
import CommunityContextProvider from '../../_contexts/CommunityContext';
import CommunityPage from '../CommunityPage';
import DiscoverContextProvider from '../../_contexts/DiscoverContext';
import DiscoverPage from '../DiscoverPage';
import { AuthContext } from '../../_contexts/AuthContext';
import ProfilePage from '../ProfilePage';
import PublicProfilePage from '../PublicProfilePage';
// import ErrorPage from '../ErrorPage';
import 'firebase/auth';
import './style.css';

const logout = () => {
  firebase.auth().signOut().then(() => {
    document.location.assign('/');
    sessionStorage.removeItem('session');
  });
};

const getUser = async (email, putUser) => {
  const api = new BookSlothAPI();
  const result = await api.signIn(email);
  if (result) {
    putUser(result);
  }
};

const openLoginModal = () => {
  ModalManager.open(<ModalLogin onRequestClose={() => true} />);
};

function onAuthStateChange(setUserNull) {
  return firebase.auth().onAuthStateChanged((firebaseUser) => {
    if (!firebaseUser) {
      setUserNull({ isAuth: false, email: '' });
    }
  });
}

const LoggedInNavBar = () => {
  const { setUserNull } = useContext(AuthContext);
  useEffect(() => {
    onAuthStateChange(setUserNull);
  }, [setUserNull]);

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">
        <img className="logo" alt="booksloth" src="../a/i/booksloth_logo_b_01.png" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/" className="nav-link" target="">Discover</Nav.Link>
          <Nav.Link href="/community" className="nav-link" target="">Community</Nav.Link>
          <Nav.Link href="/profile" className="nav-link" target="">Profile</Nav.Link>
          {/* <a href="/"> */}
          <button
            className="Nav-Bar-Button-outlined"
            onClick={() => {
              logout();
              setUserNull();
            }}
          >
            Log out
          </button>
          {/* </a> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const LoggedOutNavBar = () => {
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="/">
        <img className="logo" alt="booksloth" src="../a/i/booksloth_logo_b_01.png" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/" className="nav-link" target="">Discover</Nav.Link>
          <Nav.Link href="/community" className="nav-link" target="">Community</Nav.Link>
          <button
            className="Nav-Bar-Button-outlined"
            onClick={openLoginModal.bind(this)}
          >
            Log in
          </button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const NavigationBar = (props) => {
  const { userEmail } = props;
  const {
    user, putUser,
  } = useContext(AuthContext);
  useEffect(() => {
    if (!user.id && userEmail) {
      getUser(userEmail, putUser);
    }
  // eslint-disable-next-line
  }, [userEmail, user, putUser]);
  if (sessionStorage.session) {
    return (
      <div>
        <LoggedInNavBar />
        <Router>
          <DiscoverContextProvider>
            <Route exact path="/" component={DiscoverPage} />
          </DiscoverContextProvider>
          <BookContextProvider>
            <Route exact path="/book/:bookId" component={BookDetailsPage} />
          </BookContextProvider>
          <CommunityContextProvider>
            <Route exact path="/community" component={CommunityPage} />
          </CommunityContextProvider>
          <Route exact path="/profile" component={ProfilePage} />
          <Route exact path="/reader/:username" component={PublicProfilePage} />
          {/* <Route path="*" component={ErrorPage} /> */}
        </Router>
      </div>
    );
  }
  return (
    <div>
      <LoggedOutNavBar />
      <Router>
        <DiscoverContextProvider>
          <Route exact path="/" component={DiscoverPage} />
        </DiscoverContextProvider>
        <BookContextProvider>
          <Route exact path="/book/:bookId" component={BookDetailsPage} />
        </BookContextProvider>
        <CommunityContextProvider>
          <Route exact path="/community" component={CommunityPage} />
        </CommunityContextProvider>
        <Route exact path="/reader/:username" component={PublicProfilePage} />
        {/* <Route exact path="/profile" component={ModalLogin} /> */}
        {/* <Route path="*" component={ErrorPage} /> */}
      </Router>
    </div>
  );
};

export default NavigationBar;
