/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import BookItem from '../BookItem';
import './style.css';

function BookList(list) {
  if (list.list.books.length > 0) {
    return (
      <div className="main-container">
        <div className="discover-list-title profile-container">
          <h2>{list.list.title}</h2>
          {/* <a href="">see all</a> */}
          <img className="discover-scroll" alt="" width="35" height="35" src="../a/i/chevron-right.png" />
          <div className="profile-books row scrolling-wrapper flex-row flex-nowrap">
            {list.list.books.map((book) => (
              <BookItem book={book} key={book.id} />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default BookList;
