import React from 'react';
import BookList from '../BookList';
import useCuratedLists from '../../_hooks/curated_lists';
// import useRecommendedLists from '../../_hooks/recommended_lists';
import './style.css';

const DiscoverPage = () => {
  const curatedLists = useCuratedLists();
  if (curatedLists) {
    return (
      <div className="discover-component">
        <div className="discover-booklists">
          <h1 className="page-title">Discover</h1>
          {curatedLists ? curatedLists.map((list) => (
            <BookList list={list} key={list.id} />
          )) : null}
        </div>
      </div>
    );
  }
  return null;
};

export default DiscoverPage;
