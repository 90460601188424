/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useContext, useEffect,
} from 'react';
import BookItem from './BookItem';
import BookSlothAPI from '../../../_utils/api';
import { AuthContext } from '../../../_contexts/AuthContext';

const loadMoreBooks = async (userId, type, page, bookLists, prevBooks, putBookLists) => {
  const api = new BookSlothAPI();
  const result = await api.paginateUserBookLists(userId, type, page);
  if (result) {
    const books = prevBooks ? ([...prevBooks, ...result]) : [];
    putBookLists(bookLists, type, books);
  }
};

const getBookLists = async (user, putBookLists) => {
  const api = new BookSlothAPI();
  const result = await api.getUserBookLists(user.id || JSON.parse(sessionStorage.session));
  if (result) {
    putBookLists(result, null, null);
  }
};

function UserBookList() {
  const {
    user, page, bookLists, putBookLists,
  } = useContext(AuthContext);
  useEffect(() => {
    if (!bookLists) {
      getBookLists(user, putBookLists);
    }
  });
  if (bookLists && bookLists.length > 0) {
    return (
      <div className="main-container">
        {bookLists.map((bookList) => (
          bookList.type.toLowerCase().replace(' ', '') === 'notforme' || bookList.booksCount === 0
            ? (<div key={bookList.id} />)
            : (
              <div className="profile-container" key={bookList.id}>
                <h2>{bookList.title}</h2>
                {/* <a href="">see all</a> */}
                <div className="profile-books row scrolling-wrapper flex-row flex-nowrap">
                  {bookList.books.map((book, index) => (
                    index + 1 === bookList.books.length
                    && bookList.books.length >= 10
                    && bookList.books.length < bookList.booksCount
                      ? (
                        <div>
                          <BookItem book={book} key={book.id} />
                          {' '}
                          <button
                            type="button"
                            onClick={() => loadMoreBooks(
                              user.id || JSON.parse(sessionStorage.session),
                              bookList.type,
                              page,
                              bookLists,
                              bookList.books,
                              putBookLists,
                            )}
                          >
                            +
                          </button>
                        </div>
                      )
                      : <BookItem book={book} key={book.id} />
                  ))}
                </div>
              </div>
            )
        ))}
      </div>
    );
  }
  return null;
}

export default UserBookList;
