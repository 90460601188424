import React from 'react';
import BookElementList from '../BookElementList';
import BookCommentList from '../BookCommentList';
import './style.css';

function BookReviews() {
  return (
    <div className="book-detail main-container">
      <div className="book-detail-reviews">
        <h3>Community Reviews</h3>
        <BookElementList />
        <BookCommentList />
      </div>
    </div>
  );
}

export default BookReviews;
