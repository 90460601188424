/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useParams } from 'react-router-dom';
import useBookComments from '../../_hooks/book_comments';
import BookCommentItem from '../BookCommentItem';
import './style.css';

function BookCommentList() {
  const { bookId } = useParams();
  const bookComments = useBookComments(bookId);

  if (bookComments) {
    const halfLength = Math.ceil(bookComments.length / 2);
    let leftSide = bookComments.slice(0, halfLength);
    let rightSide = bookComments.slice(halfLength, bookComments.length);

    leftSide = leftSide.sort((a, b) => b.review.length - a.review.length);
    rightSide = rightSide.sort((a, b) => b.review.length - a.review.length);

    return (
      <div className="row review-comments">
        <div className="col-xs-12 col-md-6 review-comment">
          {leftSide.map((comment) => (
            <BookCommentItem comment={comment} key={comment.id} />
          ))}
        </div>
        <div className="col-xs-12 col-md-6 review-comment">
          {rightSide.map((comment) => (
            <BookCommentItem comment={comment} key={comment.id} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}

export default BookCommentList;
