/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { createContext, useState } from 'react';

export const DiscoverContext = createContext();

const DiscoverContextProvider = (props) => {
  const [curatedLists, setCuratedLists] = useState();
  const [recommendedLists, setRecommendedLists] = useState();
  return (
    <DiscoverContext.Provider value={{
      curatedLists,
      recommendedLists,
      setCuratedLists,
      setRecommendedLists,
    }}
    >
      {props.children}
    </DiscoverContext.Provider>
  );
};

export default DiscoverContextProvider;
