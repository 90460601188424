/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import parse from 'html-react-parser';
import { useHistory, useParams } from 'react-router-dom';
import { ModalManager } from 'react-dynamic-modal';
import { logEvent, actionEvent, locationEvent } from '../../_utils/analytics';
import { AuthContext } from '../../_contexts/AuthContext';
import BookRating from '../BookRating';
import ReadMoreModal from './ReadMoreModal';
import DownloadModal from '../DownloadModal';
import useBookDetails from '../../_hooks/book_details';
import '../../_helpers/css/bootstrap.min.css';
import './style.css';

const openReadMoreModal = (book, userId) => {
  ModalManager.open(<ReadMoreModal book={book} onRequestClose={() => true} />);
  // Analytics
  logEvent(book.id, userId, actionEvent.readMore, locationEvent.bookDetails);
};
const openAddBookModal = (bookId, userId) => {
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
  // Analytics
  logEvent(bookId, userId, actionEvent.addBook, locationEvent.bookDetails);
};
const openBuyBookModal = (bookId, userId) => {
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
  // Analytics
  logEvent(bookId, userId, actionEvent.buyBook, locationEvent.bookDetails);
};
const openReviewModal = (bookId, userId) => {
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
  // Analytics
  logEvent(bookId, userId, actionEvent.leaveReview, locationEvent.bookDetails);
};

function BookInfoItem() {
  const { bookId } = useParams();
  const { user } = useContext(AuthContext);
  const bookDetails = useBookDetails(bookId);

  if (bookDetails) {
    const history = useHistory();

    return (
      <div className="main-container">
        <div className="book-detail">
          <div className="book-detail-container">
            <div className="back-button">
              {/* <a href="/"> */}
              <button
                type="button"
                className="btn btn-primary"
                onClick={history.goBack}
              >
                <img className="button-image" width="14" alt="" src="../a/i/chevron_left_white.svg" />
                Go Back
              </button>
              {/* </a> */}
            </div>
            <div className="row align-items-center">
              <div className="cover-image col-xs-12 col-md-4">
                <img className="book-cover-image" alt="books" src={bookDetails.cover_photo} />
              </div>
              <div className="col-xs-12 col-md-8 book-description">
                <h2>{bookDetails.title}</h2>
                <div className="book-author-names">
                  {bookDetails.authors.map((author, index) => (
                    <h3 key={author.id}>
                      {author.first_name}
                      {' '}
                      {author.last_name}
                      {index + 1 !== bookDetails.authors.length ? ', ' : ''}
                    </h3>
                  ))}
                </div>
                <div className="star-ratings">
                  <p>
                    <BookRating starRating={bookDetails.average_rating} />
                  </p>
                </div>
                <p>
                  Pages:
                  <span>{bookDetails.pages}</span>
                </p>
                <p>
                  Pub Date:
                  <span>{bookDetails.pub_date}</span>
                </p>
                <p>{parse(bookDetails.description)}</p>
                <button
                  type="button"
                  className="read-more-button"
                  onClick={openReadMoreModal.bind(this, bookDetails, user.id)}
                >
                  Read More
                </button>
                <div className="row book-detail-actions">
                  <button
                    type="button"
                    className="btn btn-primary add-book"
                    onClick={openAddBookModal.bind(this, bookDetails.id, user.id)}
                  >
                    <img className="button-image" width="22" alt="add book" src="../a/i/books_white.svg" />
                    Add Book
                  </button>
                  <button
                    type="button"
                    className="btn btn-default review-book"
                    onClick={openReviewModal.bind(this, bookDetails.id, user.id)}
                  >
                    <img className="button-image" width="22" alt="review" src="../a/i/pencil_p.svg" />
                    Review
                  </button>
                  {bookDetails.affiliate_link
                    ? (
                      <a href={bookDetails.affiliate_link} target="_blank" rel="noreferrer">
                        <button
                          type="button"
                          className="btn btn-default buy-book"
                          onClick={openBuyBookModal.bind(this, bookDetails.id, user.id)}
                        >
                          <img className="button-image" width="22" alt="buy book" src="../a/i/shopping_bag_p.svg" />
                          Buy Book
                        </button>
                      </a>
                    )
                    : (null)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default BookInfoItem;
