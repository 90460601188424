/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext } from 'react';
import { ModalManager } from 'react-dynamic-modal';
// import AuthContext from '../../../_contexts/AuthContext';
// import BookImport from '../../BookImport';
import { AuthContext } from '../../../_contexts/AuthContext';
import { logEvent, actionEvent, locationEvent } from '../../../_utils/analytics';
import DownloadModal from '../../DownloadModal';

const openBookImportModal = (userId) => {
  // ModalManager.open(<BookImport />);
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
  // Analytics
  logEvent(null, userId, actionEvent.importBooks, locationEvent.profile);
};

// const openGoodreads = () => {
//   window.location.href = 'https://www.goodreads.com/review/import';
// };

// const importBooks = async () => {
//   // const result = await api.bookImport(file);
//   // if(result) {
//   //   // reload profile
//   // }
// };

function BookImportButton() {
  // const [user] = useState();
  const { user } = useContext(AuthContext);
  return (
    <button
      type="button"
      className="btn btn-main profile-stats"
      data-bs-toggle="modal"
      data-bs-target="#onboard-import"
      onClick={() => {
        openBookImportModal(user.id);
      }}
    >
      Import Goodreads
      {/* <a href={`/import`}>Import Goodreads</a> */}
    </button>
  );
}

// const BookImport = () => {
//   const [selectedFile, setSelectedFile] = useState('');
//   // const [user] = useState();
//   // const { user } = useContext(AuthContext);
//   // console.log(`USER IN PROP: ${user.id}`);
//   // const api = new BooksAPI(user.user);

//   return (
//     <Modal onRequestClose={false} effect={Effect.ScaleUp} className="react-dynamic-modal">
//       {/* <div className="modal-dialog modal-dialog-centered onboarding"> */}
//       <div id="gr-import" tabIndex="-1" role="dialog">
//         <div className="modal-content">
//           <div className="modal-header">
//             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ModalManager.close}>
//               <img src="../a/i/modal_close.png" alt="" />
//             </button>
//           </div>
//           <div className="modal-body gr-import">
//             <h1 className="modal-title" id="exampleModalLabel">Import your Goodreads library to your Profile</h1>

//             <h2>Step 1</h2>
//             <p>Visit the Import and Export page on Goodreads and click the "Export Library" button to download your .csv file.</p>
//             <button
//               className="gr-import"
//               onClick={() => { openGoodreads(); }}
//             >
//               Export my Library from Goodreads
//             </button>
//             <h2>Step 2</h2>
//             <p>Choose and upload the .csv file you downloaded from Step 1.</p>
//             <input
//               type="file"
//               className="gr-import form-control"
//               id="customFile"
//               value={selectedFile}
//               onChange={(e) => setSelectedFile(e.target.files[0])}
//             />
//             <h2>Step 3</h2>
//             <p>Start your import</p>
//             <button
//               className="gr-import"
//               onClick={() => importBooks(selectedFile)}
//             >
//               Import Books
//             </button>
//           </div>
//           <div className="modal-footer">
//             {/* <a href="" className="gr-import">Not Now</a>
//                     <a href="" className="previous">Previous</a> */}
//           </div>
//         </div>
//       </div>
//       {/* </div> */}
//     </Modal>
//   );
// };

export default BookImportButton;
