/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import ProfileBio from './ProfileBio';
import UserBookList from './UserBookList';
import BookImportButton from '../Buttons/BookImportButton';
// import ReadingStatsButton from '../Buttons/ReadingStatsButton';
import ReadingStats from './ReadingStats';
import YearReviewContextProvider from '../../_contexts/YearReviewContext';
import './style.css';

const ProfilePage = () => {
  const [showStats, setShowStats] = useState(false);
  return (
    <div className="profile-component">
      <div className="profile-info">
        <div className="cover-photo-container">
          <img className="profile-cover-photo" alt="bookshelf cover img" src="../../a/i/profile-cover-img.png" />
        </div>
        <ProfileBio />
      </div>
      <div className="profile-booklists">
        <div className="booklists-actions">
          <BookImportButton />
          {/* <ReadingStatsButton /> */}
          <button
            type="button"
            className="btn btn-primary profile-stats"
            onClick={() => setShowStats(!showStats)}
          >
            {showStats ? 'Your Library' : 'Reading Stats'}
          </button>
        </div>
        {showStats
          ? (
            <YearReviewContextProvider>
              <ReadingStats />
            </YearReviewContextProvider>
          )
          : <UserBookList />}
        {/* <UserBookList /> */}
      </div>
    </div>
  );
};

export default ProfilePage;
