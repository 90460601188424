import { useEffect, useContext } from 'react';
import { CommunityContext } from '../_contexts/CommunityContext';
import cachedPosts from '../_components/CommunityPage/cached';
import BookSlothAPI from '../_utils/api';

require('dotenv').config();

const getCommunityPosts = async (page, putPosts) => {
  const api = new BookSlothAPI();
  const result = await api.getCommunityPosts(page);
  if (result) {
    putPosts(result);
  }
};

const useCommunityPosts = () => {
  const { page, posts, putPosts } = useContext(CommunityContext);

  useEffect(() => {
    if (!posts) {
      getCommunityPosts(page, putPosts);
    }
  });

  // return (process.env.NODE_ENV === 'staging') ? posts || communityPosts : posts;
  return posts || cachedPosts;
};

export default useCommunityPosts;
