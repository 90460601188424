/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { useParams } from 'react-router-dom';
import BookElementItem from '../BookElementItem';
import useBookElements from '../../_hooks/book_elements';
import '../../_helpers/css/bootstrap.min.css';
import './style.css';

function BookElementList() {
  const { bookId } = useParams();
  const bookElements = useBookElements(bookId);
  return (
    <div className="book-detail row review-badges row scrolling-wrapper flex-row flex-nowrap">
      {bookElements ? (bookElements.map((element) => (
        <BookElementItem element={element} key={element.id} />
      ))) : null}
    </div>
  );
}

export default BookElementList;
