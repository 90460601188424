import axios from 'axios';
// import firebase from 'firebase/app';
import 'firebase/auth';

require('dotenv').config();

const BASE_URL = process.env.REACT_APP_BASE_URL;
const token = process.env.REACT_APP_API_TOKEN;
const postPerPage = process.env.REACT_APP_POSTS_PER_PAGE;

class BookSlothAPI {
  constructor() {
    this.get = {
      method: 'GET',
      headers: { 'x-api-key': `${token}` },
    };
    this.post = {
      method: 'POST',
      headers: { 'x-api-key': `${token}` },
    };
  }

  async signIn(email) {
    this.get.url = `${BASE_URL}/users/${email}`;
    return axios(this.get).then(async (result) => {
      return result.data;
      // try {
      //   const idToken = await firebase.auth().currentUser.getIdToken(true);
      //   return {
      //     user: result.data.user,
      //     token: idToken,
      //   };
      // } catch (e) {
      //   return {
      //     user: result.data.user,
      //     token: null,
      //   };
      // }
    }).catch(() => {
      return null;
    });
  }

  async getUser(username) {
    this.get.url = `${BASE_URL}/users/id/${username}`;
    return axios(this.get).then(async (result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async logEvent(params) {
    this.post.url = `${BASE_URL}/analytics`;
    this.post.data = params;
    return axios(this.post).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async editUserInfo(params) {
    this.put.url = `${BASE_URL}/users/${this.userEmail}`;
    this.post.data = params;
    return axios(this.post).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async getBookInfo(bookId) {
    this.get.url = `${BASE_URL}/books/${bookId}`;
    return axios(this.get).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async getBookElements(bookId) {
    this.get.url = `${BASE_URL}/books/${bookId}/bookbadges`;
    return axios(this.get).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async getBookComments(bookId) {
    this.get.url = `${BASE_URL}/books/${bookId}/reviews`;
    return axios(this.get).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async getCuratedLists() {
    this.get.url = `${BASE_URL}/curatedlists`;
    return axios(this.get).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async getDynamicLink(username) {
    this.post.url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDZvT4GuZZ5Wffhdv1FFYBr-w2_hfG3n3o';
    this.post.data = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://booksloth.page.link',
        link: `https://beta.booksloth.com/${username}`,
        socialMetaTagInfo: {
          socialTitle: 'My BookSloth Profile',
          socialDescription: 'My BookSloth Profile',
          socialImageLink:
              'https://s3.amazonaws.com/year-in-review.booksloth.com/background_image.png',
        },
      },
      suffix: {
        option: 'SHORT',
      },
    };
    return axios(this.post).then((result) => {
      return result.data.shortLink;
    }).catch(() => {
      return null;
    });
  }

  async getRecommendedLists() {
    // this.get.url = `${BASE_URL}/recommendedbooklists`;
    this.get.url = 'https://booksloth-book-assets.s3.amazonaws.com/payload/recommended-lists';
    return axios(this.get).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async getBookReviews(bookId) {
    this.get.url = `${BASE_URL}/books/${bookId}/reviews`;
    return axios(this.get).then((result) => {
      return result.data[0].Posts;
    }).catch(() => {
      return null;
    });
  }

  async bookImport(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', 'csv');

    this.post.url = `${BASE_URL}/users/${this.userId}/source/goodreads/importbooks`;
    this.post.data = formData;
    // return await axios(this.post).then((result) => {
    //     return result.data;
    // }).catch((_) => {
    //     return null
    // });
  }

  async getCommunityPosts(page) {
    this.get.url = `${BASE_URL}/discussions/community?page=${page}&perPage=${postPerPage}`;
    return axios(this.get).then((result) => {
      return result.data[0].Posts;
    }).catch(() => {
      return null;
    });
  }

  async getUserBookLists(userId) {
    this.get.url = `${BASE_URL}/users/${userId}/userbooklists/all`;
    return axios(this.get).then((result) => {
      return result.data;
    }).catch(() => {
      return null;
    });
  }

  async paginateUserBookLists(userId, type, page) {
    this.get.url = `${BASE_URL}/users/${userId}/userbooklists/${type}?page=${page}&perPage=10`;
    return axios(this.get).then((result) => {
      return result.data.books;
    }).catch(() => {
      return null;
    });
  }
}

export default BookSlothAPI;
