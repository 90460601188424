/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from 'react';
import BookItem from '../../ProfilePage/UserBookList/BookItem';
import BookSlothAPI from '../../../_utils/api';
import { AuthContext } from '../../../_contexts/AuthContext';

const getBookLists = async (user, putBookLists) => {
  const api = new BookSlothAPI();
  const result = await api.getUserBookLists(user.id);
  if (result) {
    putBookLists(result);
  }
};

function PublicUserBookList() {
  const {
    publicUser, publicBookLists, putPublicBookLists,
  } = useContext(AuthContext);
  useEffect(() => {
    if (!publicBookLists) {
      getBookLists(publicUser, putPublicBookLists);
    }
  });
  if (publicBookLists && publicBookLists.length > 0) {
    return (
      <div className="main-container">
        {publicBookLists.map((bookList) => (
          bookList.books.length > 0 && bookList.type.toLowerCase().replace(' ', '') !== 'notforme'
            ? (
              <div className="profile-container" key={bookList.id}>
                <h2>{bookList.title}</h2>
                {/* <a href="">see all</a> */}
                <div className="profile-books row scrolling-wrapper flex-row flex-nowrap">
                  {bookList.books.map((book) => (
                    <BookItem book={book} key={book.id} />
                  ))}
                </div>
              </div>
            ) : (<div key={bookList.id} />)
        ))}
      </div>
    );
  }
  return null;
}

export default PublicUserBookList;
