/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Modal, ModalManager, Effect } from 'react-dynamic-modal';
import './style.css';

const DownloadModal = () => {
  const customStyle = {
    content: {
      borderRadius: '4px',
      height: 'auto',
      width: '500px',
    },
  };
  return (
    <Modal
      onRequestClose={false}
      effect={Effect.ScaleUp}
      className="react-dynamic-modal"
      style={customStyle}
    >
      <div id="add-book" tabIndex="-1" role="dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={ModalManager.close}>
              <img src="../a/i/modal_close.png" alt="" />
            </button>
          </div>
          <img className="background-img" src="../top_books_art.png" alt="" />
          <div className="modal-body add-book">
            <img className="logo-img" src="../a/i/booksloth_logo_white.png" alt="" />
            <h2>For the full BookSloth experience download our app.</h2>
            <p>
              Join our community to add or review books and more!
              These features and more will be coming soon to web.
            </p>
            <div className="app-download">
              <a href="https://itunes.apple.com/ng/app/booksloth/id1399024480?mt=8&ign-mpt=uo%3D2" target="_blank" rel="noreferrer"><img className="app-store" src="../a/i/app-store.png" alt="" /></a>
              <a href="https://play.google.com/store/apps/details?id=com.booksloth.android" target="_blank" rel="noreferrer"><img className="app-store google" src="../a/i/play-store.png" alt="" /></a>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default DownloadModal;
