const discoverLists = [
  {
    id: 39,
    title: 'Top Book Clubs on BookSloth',
    description: 'Top Book Clubs on BookSloth',
    books: [
      {
        id: 9767,
        title: 'House of Earth and Blood (Crescent City, #1)',
        description: 'Half-Fae, half-human Bryce Quinlan loves her life. By day, she works for an antiquities dealer, selling barely legal magical artifacts, and by night, she parties with her friends, savouring every pleasure Lunathion—otherwise known as Crescent City— has to offer. But it all comes crumbling down when a ruthless murder shakes the very foundations of the city—and Bryce’s world.Two years later, her job has become a dead end, and she now seeks only blissful oblivion in the city’s most notorious nightclubs. But when the murderer attacks again, Bryce finds herself dragged into the investigation and paired with an infamous Fallen angel whose own brutal past haunts his every step.Hunt Athalar, personal assassin for the Archangels, wants nothing to do with Bryce Quinlan, despite being ordered to protect her. She stands for everything he once rebelled against and seems more interested in partying than solving the murder, no matter how close to home it might hit. But Hunt soon realizes there’s far more to Bryce than meets the eye—and that he’s going to have to find a way to work with her if they want to solve this case.As Bryce and Hunt race to untangle the mystery, they have no way of knowing the threads they tug ripple through the underbelly of the city, across warring continents, and down to the darkest levels of Hel, where things that have been sleeping for millennia are beginning to stir...',
        pub_id: null,
        pub_date: '2020-01-28',
        serie_id: null,
        pages: 803,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/House_of_Earth_and_Blood__Crescent_City___1_.jpeg',
        edition: '1',
        average_rating: '4.46',
        authors: [
          {
            id: 32,
            first_name: 'Sarah',
            last_name: 'J. Maas',
          },
        ],
      },
      {
        id: 63,
        title: 'Pride and Prejudice',
        description: 'Introduction by Anna Quindlen\nCommentary by Margaret Oliphant, George Saintsbury, Mark Twain, A. C. Bradley, Walter A. Raleigh, and Virginia Woolf\n\nNominated as one of America’s best-loved novels by PBS’s The Great American Read',
        pub_id: null,
        pub_date: '2019-03-01',
        serie_id: null,
        pages: 304,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Pride_and_Prejudice.jpeg',
        edition: '1.00',
        average_rating: '4.48',
        authors: [
          {
            id: 90,
            first_name: 'Jane',
            last_name: 'Austen',
          },
        ],
      },
      {
        id: 37158,
        title: 'The Invisible Life of Addie LaRue',
        description: 'A Life No One Will Remember. A Story You Will Never Forget. France, 1714: in a moment of desperation, a young woman makes a Faustian bargain to live forever—and is cursed to be forgotten by everyone she meets. Thus begins the extraordinary life of Addie LaRue, and a dazzling adventure that will play out across centuries and continents, across history and art, as a young woman learns how far she will go to leave her mark on the world. But everything changes when, after nearly 300 years, Addie stumbles across a young man in a hidden bookstore and he remembers her name. In the vein of The Time Traveler’s Wife and Life After Life, The Invisible Life of Addie LaRue is New York Times bestselling author V. E. Schwab’s genre-defying tour de force.',
        pub_id: null,
        pub_date: '2020-10-06',
        serie_id: null,
        pages: 464,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Invisible_Life_of_Addie_LaRue.jpeg',
        edition: '1.0',
        average_rating: '4.45',
        authors: [
          {
            id: 28201,
            first_name: 'V.',
            last_name: 'E.',
          },
          {
            id: 345,
            first_name: 'V.E.',
            last_name: 'Schwab',
          },
        ],
      },
      {
        id: 9764,
        title: 'Red, White & Royal Blue',
        description: 'A big-hearted romantic comedy in which First Son Alex falls in love with Prince Henry of Wales after an incident of international proportions forces them to pretend to be best friends...First Son Alex Claremont-Diaz is the closest thing to a prince this side of the Atlantic. With his intrepid sister and the Veep’s genius granddaughter, they’re the White House Trio, a beautiful millennial marketing strategy for his mother, President Ellen Claremont. International socialite duties do have downsides—namely, when photos of a confrontation with his longtime nemesis Prince Henry at a royal wedding leak to the tabloids and threaten American/British relations.The plan for damage control: staging a fake friendship between the First Son and the Prince. Alex is busy enough handling his mother’s bloodthirsty opponents and his own political ambitions without an uptight royal slowing him down. But beneath Henry’s Prince Charming veneer, there’s a soft-hearted eccentric with a dry sense of humor and more than one ghost haunting him. As President Claremont kicks off her reelection bid, Alex finds himself hurtling into a secret relationship with Henry that could derail the campaign and upend two nations. And Henry throws everything into question for Alex, an impulsive, charming guy who thought he knew everything: What is worth the sacrifice? How do you do all the good you can do? And, most importantly, how will history remember you?',
        pub_id: null,
        pub_date: '2019-05-14',
        serie_id: null,
        pages: 423,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Red__White___Royal_Blue.jpeg',
        edition: '1',
        average_rating: '4.50',
        authors: [
          {
            id: 2978,
            first_name: 'Casey',
            last_name: 'McQuiston',
          },
        ],
      },
      {
        id: 6088,
        title: 'The Song of Achilles',
        description: "Greece in the age of heroes. Patroclus, an awkward young prince, has been exiled to the court of King Peleus and his perfect son Achilles. By all rights their paths should never cross, but Achilles takes the shamed prince as his friend, and as they grow into young men skilled in the arts of war and medicine their bond blossoms into something deeper - despite the displeasur\n  Greece in the age of heroes. Patroclus, an awkward young prince, has been exiled to the court of King Peleus and his perfect son Achilles. By all rights their paths should never cross, but Achilles takes the shamed prince as his friend, and as they grow into young men skilled in the arts of war and medicine their bond blossoms into something deeper - despite the displeasure of Achilles' mother Thetis, a cruel sea goddess. But then word comes that Helen of Sparta has been kidnapped. Torn between love and fear for his friend, Patroclus journeys with Achilles to Troy, little knowing that the years that follow will test everything they hold dear.Profoundly moving and breathtakingly original, this rendering of the epic Trojan War is a dazzling feat of the imagination, a devastating love story, and an almighty battle between gods and kings, peace and glory, immortal fame and the human heart.",
        pub_id: null,
        pub_date: '2011-09-20',
        serie_id: null,
        pages: 352,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Song_of_Achilles.jpeg',
        edition: '1.0',
        average_rating: '4.58',
        authors: [
          {
            id: 1184,
            first_name: 'Madeline',
            last_name: 'Miller',
          },
        ],
      },
      {
        id: 6753,
        title: 'Circe',
        description: 'In the house of Helios, god of the sun and mightiest of the Titans, a daughter is born. But Circe is a strange child—not powerful, like her father, nor viciously alluring like her mother. Turning to the world of mortals for companionship, she discovers that she does possess power—the power of witchcraft, which can transform rivals into monsters and menace the gods themselves.\n\nThreatened, Zeus banishes her to a deserted island, where she hones her occult craft, tames wild beasts and crosses paths with many of the most famous figures in all of mythology, including the Minotaur, Daedalus and his doomed son Icarus, the murderous Medea, and, of course, wily Odysseus.\n\nBut there is danger, too, for a woman who stands alone, and Circe unwittingly draws the wrath of both men and gods, ultimately finding herself pitted against one of the most terrifying and vengeful of the Olympians. To protect what she loves most, Circe must summon all her strength and choose, once and for all, whether she belongs with the gods she is born from, or the mortals she has come to love.',
        pub_id: null,
        pub_date: '2018-04-10',
        serie_id: null,
        pages: 394,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Circe.jpeg',
        edition: '1.00',
        average_rating: '4.36',
        authors: [
          {
            id: 1184,
            first_name: 'Madeline',
            last_name: 'Miller',
          },
        ],
      },
      {
        id: 36750,
        title: 'The House in the Cerulean Sea',
        description: "A magical island. A dangerous task. A burning secret.Linus Baker leads a quiet, solitary life. At forty, he lives in a tiny house with a devious cat and his old records. As a Case Worker at the Department in Charge Of Magical Youth, he spends his days overseeing the well-being of children in government-sanctioned orphanages.When Linus is unexpectedly summoned by Extremely \n  A magical island. A dangerous task. A burning secret.Linus Baker leads a quiet, solitary life. At forty, he lives in a tiny house with a devious cat and his old records. As a Case Worker at the Department in Charge Of Magical Youth, he spends his days overseeing the well-being of children in government-sanctioned orphanages.When Linus is unexpectedly summoned by Extremely Upper Management he's given a curious and highly classified assignment: travel to Marsyas Island Orphanage, where six dangerous children reside: a gnome, a sprite, a wyvern, an unidentifiable green blob, a were-Pomeranian, and the Antichrist. Linus must set aside his fears and determine whether or not they’re likely to bring about the end of days.But the children aren’t the only secret the island keeps. Their caretaker is the charming and enigmatic Arthur Parnassus, who will do anything to keep his wards safe. As Arthur and Linus grow closer, long-held secrets are exposed, and Linus must make a choice: destroy a home or watch the world burn.An enchanting story, masterfully told, The House in the Cerulean Sea is about the profound experience of discovering an unlikely family in an unexpected place—and realizing that family is yours.",
        pub_id: null,
        pub_date: '2020-03-17',
        serie_id: null,
        pages: 394,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_House_in_the_Cerulean_Sea.jpeg',
        edition: '1',
        average_rating: '4.70',
        authors: [
          {
            id: 27843,
            first_name: 'T.J.',
            last_name: 'Klune',
          },
        ],
      },
      {
        id: 24,
        title: 'Six of Crows (Six of Crows, #1)',
        description: 'Ketterdam: a bustling hub of international trade where anything can be had for the right price–and no one knows that better than criminal prodigy Kaz Brekker. Kaz is offered a chance at a deadly heist that could make him rich beyond his wildest dreams. But he can’t pull it off alone…A convict with a thirst for revenge.A sharpshooter who can’t walk away from a wager.A runaway with a privileged past.A spy known as the Wraith.A Heartrender using her magic to survive the slums.A thief with a gift for unlikely escapes.Six dangerous outcasts. One impossible heist. Kaz’s crew is the only thing that might stand between the world and destruction—if they don’t kill each other first.',
        pub_id: null,
        pub_date: '2015-09-29',
        serie_id: null,
        pages: 465,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Six_of_Crows__Six_of_Crows___1_.jpeg',
        edition: '1.00',
        average_rating: '4.64',
        authors: [
          {
            id: 46,
            first_name: 'Leigh',
            last_name: 'Bardugo',
          },
        ],
      },
      {
        id: 5744,
        title: 'Dune (Dune Chronicles, #1)',
        description: "Set in the far future amidst a sprawling feudal interstellar empire where planetary dynasties are controlled by noble houses that owe an allegiance to the imperial House Corrino, Dune tells the story of young Paul Atreides (the heir apparent to Duke Leto Atreides and heir of House Atreides) as he and his family accept control of the desert planet Arrakis, the only source of the 'spice' melange, the most important and valuable substance in the cosmos. The story explores the complex, multi-layered interactions of politics, religion, ecology, technology, and human emotion as the forces of the empire confront each other for control of Arrakis.<br /><br />Published in 1965, it won the Hugo Award in 1966 and the inaugural Nebula Award for Best Novel. Dune is frequently cited as the world's best-selling sf novel.",
        pub_id: null,
        pub_date: '2006-06-01',
        serie_id: null,
        pages: 604,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Dune__Dune_Chronicles___1_.jpeg',
        edition: '1.0',
        average_rating: '4.22',
        authors: [
          {
            id: 956,
            first_name: 'Frank',
            last_name: 'Herbert',
          },
        ],
      },
      {
        id: 6580,
        title: 'The Woman in the Window',
        description: 'Anna Fox lives alone—a recluse in her New York City home, unable to venture outside. She spends her day drinking wine (maybe too much), watching old movies, recalling happier times . . . and spying on her neighbors.<br /><br />Then the Russells move into the house across the way: a father, a mother, their teenage son. The perfect family. But when Anna, gazing out her window one night, sees something she shouldn’t, her world begins to crumble—and its shocking secrets are laid bare.<br /><br />What is real? What is imagined? Who is in danger? Who is in control? In this diabolically gripping thriller, no one—and nothing—is what it seems.',
        pub_id: null,
        pub_date: '2018-01-02',
        serie_id: null,
        pages: 427,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Woman_in_the_Window.jpeg',
        edition: '1.0',
        average_rating: '3.70',
        authors: [
          {
            id: 1403,
            first_name: 'A.J.',
            last_name: ' Finn',
          },
        ],
      },
      {
        id: 4,
        title: 'Children of Blood and Bone (Legacy of Orisha, #1)',
        description: 'Zélie Adebola remembers when the soil of Orïsha hummed with magic. Burners ignited flames, Tiders beckoned waves, and Zelie’s Reaper mother summoned forth souls. But everything changed the night magic disappeared. Under the orders of a ruthless king, maji were targeted and killed, leaving Zélie without a mother and her people without hope.Now, Zélie has one chance to bring back magic and strike against the monarchy. With the help of a rogue princess, Zélie must outwit and outrun the crown prince, who is hell-bent on eradicating magic for good. Danger lurks in Orïsha, where snow leoponaires prowl and vengeful spirits wait in the waters. Yet the greatest danger may be Zélie herself as she struggles to control her powers—and her growing feelings for the enemy.',
        pub_id: null,
        pub_date: '2018-03-06',
        serie_id: null,
        pages: 525,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Children_of_Blood_and_Bone__Legacy_of_Orisha___1_.jpeg',
        edition: '1.00',
        average_rating: '4.24',
        authors: [
          {
            id: 4,
            first_name: 'Tomi',
            last_name: 'Adeyemi',
          },
          {
            id: 31101,
            first_name: 'K.J.',
            last_name: 'Wignall',
          },
        ],
      },
      {
        id: 22759,
        title: 'The Ballad of Songbirds and Snakes',
        description: 'The Ballad of Songbirds and Snakes will revisit the world of Panem sixty-four years before the events of The Hunger Games, starting on the morning of the reaping of the Tenth Hunger Games.',
        pub_id: null,
        pub_date: '2020-05-19',
        serie_id: null,
        pages: 540,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Ballad_of_Songbirds_and_Snakes.jpeg',
        edition: '1',
        average_rating: '3.49',
        authors: [
          {
            id: 35,
            first_name: 'Suzanne',
            last_name: 'Collins',
          },
        ],
      },
      {
        id: 47445,
        title: 'Midnight Sun (Twilight, #5)',
        description: 'When Edward Cullen and Bella Swan met in Twilight, an iconic love story was born. But until now, fans have heard only Bella’s side of the story. At last, readers can experience Edward’s version in the long-awaited companion novel, Midnight Sun.This unforgettable tale as told through Edward’s eyes takes on a new and decidedly dark twist. Meeting beautiful, mysterious Bella is both the most intriguing and unnerving event he has experienced in his long life as a vampire. As we learn more fascinating details about Edward’s past and the complexity of his inner thoughts, we understand why this is the defining struggle of his life. How can he let himself fall in love with Bella when he knows that he is endangering her life?In Midnight Sun, Stephenie Meyer transports us back to a world that has captivated millions of readers and, drawing on the classic myth of Hades and Persephone, brings us an epic novel about the profound pleasures and devastating consequences of immortal love.',
        pub_id: null,
        pub_date: '2020-08-04',
        serie_id: null,
        pages: 672,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Midnight_Sun__Twilight___5_.jpeg',
        edition: '1',
        average_rating: '3.58',
        authors: [
          {
            id: 36,
            first_name: 'Stephenie',
            last_name: 'Meyer',
          },
        ],
      },
      {
        id: 42441,
        title: 'Mexican Gothic',
        description: 'An isolated mansion. A chillingly charismatic artistocrat. And a brave socialite drawn to expose their treacherous secrets. . . . From the author of Gods of Jade and Shadow comes “a terrifying twist on classic gothic horror” (Kirkus Reviews) set in glamorous 1950s Mexico—“fans of classic novels like Jane Eyre and Rebecca are in for a suspenseful treat” (PopSugar).After receiving a frantic letter from her newly-wed cousin begging for someone to save her from a mysterious doom, Noemí Taboada heads to High Place, a distant house in the Mexican countryside. She’s not sure what she will find—her cousin’s husband, a handsome Englishman, is a stranger, and Noemí knows little about the region.  Noemí is also an unlikely rescuer: She’s a glamorous debutante, and her chic gowns and perfect red lipstick are more suited for cocktail parties than amateur sleuthing. But she’s also tough and smart, with an indomitable will, and she is not afraid: Not of her cousin’s new husband, who is both menacing and alluring; not of his father, the ancient patriarch who seems to be fascinated by Noemí; and not even of the house itself, which begins to invade Noemi’s dreams with visions of blood and doom. Her only ally in this inhospitable abode is the family’s youngest son. Shy and gentle, he seems to want to help Noemí, but might also be hiding dark knowledge of his family’s past. For there are many secrets behind the walls of High Place. The family’s once colossal wealth and faded mining empire kept them from prying eyes, but as Noemí digs deeper she unearths stories of violence and madness.  And Noemí, mesmerized by the terrifying yet seductive world of High Place, may soon find it impossible to ever leave this enigmatic house behind.',
        pub_id: null,
        pub_date: '2020-06-30',
        serie_id: null,
        pages: 352,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Mexican_Gothic.jpeg',
        edition: '1',
        average_rating: '4.21',
        authors: [
          {
            id: 1146,
            first_name: 'Silvia',
            last_name: 'Moreno-Garcia',
          },
        ],
      },
      {
        id: 43427,
        title: 'A Song of Wraiths and Ruin',
        description: 'The first in an fantasy duology inspired by West African folklore in which a grieving crown princess and a desperate refugee find themselves on a collision course to murder each other despite their growing attraction.For Malik, the Solstasia festival is a chance to escape his war-stricken home and start a new life with his sisters in the prosperous desert city of Ziran. But when a vengeful spirit abducts Malik’s younger sister, Nadia, as payment into the city, Malik strikes a fatal deal—kill Karina, Crown Princess of Ziran, for Nadia’s freedom.But Karina has deadly aspirations of her own. Her mother, the Sultana, has been assassinated; her court threatens mutiny; and Solstasia looms like a knife over her neck. Grief-stricken, Karina decides to resurrect her mother through ancient magic . . . requiring the beating heart of a king. And she knows just how to obtain one: by offering her hand in marriage to the victor of the Solstasia competition.When Malik rigs his way into the contest, they are set on a course to destroy each other. But as attraction flares between them and ancient evils stir, will they be able to see their tasks to the death?',
        pub_id: null,
        pub_date: '2020-06-02',
        serie_id: null,
        pages: 480,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/A_Song_of_Wraiths_and_Ruin.jpeg',
        edition: '1',
        average_rating: '4.32',
        authors: [
          {
            id: 32325,
            first_name: 'Roseanne A.',
            last_name: 'Brown',
          },
        ],
      },
    ],
  },
  {
    id: 32,
    title: 'Best of Young Adult Fiction',
    description: 'Best of YA',
    books: [
      {
        id: 10543,
        title: 'The Fountains of Silence',
        description: "Madrid, 1957. Under the fascist dictatorship of General Francisco Franco, Spain is hiding a dark secret. Meanwhile, tourists and foreign businessmen flood into Spain under the welcoming guise of sunshine and wine. Among them is eighteen-year-old Daniel Matheson, the son of a Texas oil tycoon, who arrives in Madrid with his parents hoping to connect with the country of his mother's birth through the lens of his camera. Photography--and fate--introduce him to Ana, whose family's interweaving obstacles reveal the lingering grasp of the Spanish Civil War--as well as chilling definitions of fortune and fear. Daniel's photographs leave him with uncomfortable questions amidst shadows of danger. He is backed into a corner of decisions to protect those he loves. Lives and hearts collide, revealing an incredibly dark side to the sunny Spanish city.Master storyteller Ruta Sepetys once again shines light into one of history's darkest corners in this epic, heart-wrenching novel about identity, unforgettable love, repercussions of war, and the hidden violence of silence--inspired by the true post-war struggles of Spain.",
        pub_id: null,
        pub_date: '2019-10-01',
        serie_id: null,
        pages: 512,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Fountains_of_Silence.jpeg',
        edition: '1',
        average_rating: '4.37',
        authors: [
          {
            id: 581,
            first_name: 'Ruta',
            last_name: 'Sepetys',
          },
        ],
      },
      {
        id: 11855,
        title: 'Frankly in Love (Frankly in Love, #1)',
        description: 'High school senior Frank Li is a Limbo–his term for Korean-American kids who find themselves caught between their parents’ traditional expectations and their own Southern California upbringing. His parents have one rule when it comes to romance–“Date Korean”–which proves complicated when Frank falls for Brit Means, who is smart, beautiful–and white. Fellow Limbo Joy Song is in a similar predicament, and so they make a pact: they’ll pretend to date each other in order to gain their freedom. Frank thinks it’s the perfect plan, but in the end, Frank and Joy’s fake-dating maneuver leaves him wondering if he ever really understood love–or himself–at all.',
        pub_id: null,
        pub_date: '2019-09-10',
        serie_id: null,
        pages: 432,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Frankly_in_Love__Frankly_in_Love___1_.jpeg',
        edition: '1',
        average_rating: '3.76',
        authors: [
          {
            id: 4464,
            first_name: 'David',
            last_name: 'Yoon',
          },
        ],
      },
      {
        id: 33749,
        title: 'Birthday',
        description: 'Boyhood meets The Sun Is Also a Star in this unconventional love story by award-winning author Meredith Russo!Two kids, Morgan and Eric, are bonded for life after being born on the same day at the same time. We meet them once a year on their shared birthday as they grow and change: as Eric figures out who he is and how he fits into the world, and as Morgan makes the difficult choice to live as her true self. Over the years, they will drift apart, come together, fight, make up, and break up—and ultimately, realize how inextricably they are a part of each other.',
        pub_id: null,
        pub_date: '2019-05-21',
        serie_id: null,
        pages: 288,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Birthday.jpeg',
        edition: '1',
        average_rating: '4.49',
        authors: [
          {
            id: 1472,
            first_name: 'Meredith',
            last_name: 'Russo',
          },
        ],
      },
      {
        id: 9845,
        title: 'I Wish You All the Best',
        description: "When Ben De Backer comes out to their parents as nonbinary, they're thrown out of their house and forced to move in with their estranged older sister, Hannah, and her husband, Thomas, whom Ben has never even met. Struggling with an anxiety disorder compounded by their parents' rejection, they come out only to Hannah, Thomas, and their therapist and try to keep a low profile in a new school.But Ben's attempts to survive the last half of senior year unnoticed are thwarted when Nathan Allan, a funny and charismatic student, decides to take Ben under his wing. As Ben and Nathan's friendship grows, their feelings for each other begin to change, and what started as a disastrous turn of events looks like it might just be a chance to start a happier new life.At turns heartbreaking and joyous, I Wish You All the Best is both a celebration of life, friendship, and love, and a shining example of hope in the face of adversity.",
        pub_id: null,
        pub_date: '2019-05-14',
        serie_id: null,
        pages: 336,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/I_Wish_You_All_the_Best.jpeg',
        edition: '1',
        average_rating: '4.19',
        authors: [
          {
            id: 2991,
            first_name: 'Mason',
            last_name: 'Deaver',
          },
        ],
      },
      {
        id: 7335,
        title: 'Serious Moonlight',
        description: 'After an awkward first encounter, Birdie and Daniel are forced to work together in a Seattle hotel where a famous author leads a mysterious and secluded life in this romantic contemporary novel from the author of Alex, Approximately.Mystery-book aficionado Birdie Lindberg has an overactive imagination. Raised in isolation and homeschooled by strict grandparents, she’s cultivated a whimsical fantasy life in which she plays the heroic detective and every stranger is a suspect. But her solitary world expands when she takes a job the summer before college, working the graveyard shift at a historic Seattle hotel.In her new job, Birdie hopes to blossom from introverted dreamer to brave pioneer, and gregarious Daniel Aoki volunteers to be her guide. The hotel’s charismatic young van driver shares the same nocturnal shift and patronizes the waterfront Moonlight Diner where she waits for the early morning ferry after work. Daniel also shares her appetite for intrigue, and he’s stumbled upon a real-life mystery: a famous reclusive writer—never before seen in public—might be secretly meeting someone at the hotel.To uncover the writer’s puzzling identity, Birdie must come out of her shell…discovering that most confounding mystery of all may be her growing feelings for the elusive riddle that is Daniel.',
        pub_id: null,
        pub_date: '2019-04-16',
        serie_id: null,
        pages: 432,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Serious_Moonlight.jpeg',
        edition: '1',
        average_rating: '3.79',
        authors: [
          {
            id: 18,
            first_name: 'Jenn',
            last_name: 'Bennett',
          },
        ],
      },
      {
        id: 7329,
        title: 'Two Can Keep a Secret',
        description: "Echo Ridge is small-town America. Ellery's never been there, but she's heard all about it. Her aunt went missing there at age seventeen. And only five years ago, a homecoming queen put the town on the map when she was killed. Now Ellery has to move there to live with a grandmother she barely knows.The town is picture-perfect, but it's hiding secrets. And before school even begins for Ellery, someone's declared open season on homecoming, promising to make it as dangerous as it was five years ago. Then, almost as if to prove it, another girl goes missing.Ellery knows all about secrets. Her mother has them; her grandmother does too. And the longer she's in Echo Ridge, the clearer it becomes that everyone there is hiding something. The thing is, secrets are dangerous--and most people aren't good at keeping them. Which is why in Echo Ridge, it's safest to keep your secrets to yourself.",
        pub_id: null,
        pub_date: '2019-01-08',
        serie_id: null,
        pages: 336,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Two_Can_Keep_a_Secret.jpeg',
        edition: '1',
        average_rating: '3.91',
        authors: [
          {
            id: 931,
            first_name: 'Karen',
            last_name: 'M. McManus',
          },
        ],
      },
      {
        id: 7241,
        title: 'Again, But Better',
        description: "Shane has been doing college all wrong. Pre-med, stellar grades, and happy parents...sounds ideal -- but Shane's made zero friends, goes home every weekend, and romance...what's that?  Her life has been dorm, dining hall, class, repeat. Time's a ticking, and she needs a change -- there's nothing like moving to a new country to really mix things up. Shane signs up for a semester abroad in London. She's going to right all her college mistakes: make friends, pursue boys, and find adventure!  Easier said than done. She is soon faced with the complicated realities of living outside her bubble, and when self-doubt sneaks in, her new life starts to fall apart.  Shane comes to find that, with the right amount of courage and determination one can conquer anything. Throw in some fate and a touch of magic - the possibilities are endless.",
        pub_id: null,
        pub_date: '2019-05-07',
        serie_id: null,
        pages: 384,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Again__But_Better.jpeg',
        edition: '1',
        average_rating: '3.41',
        authors: [
          {
            id: 1786,
            first_name: 'Christine',
            last_name: 'Riccio',
          },
          {
            id: 30054,
            first_name: 'Lucie',
            last_name: 'Whitehouse',
          },
        ],
      },
      {
        id: 10457,
        title: 'With the Fire on High',
        description: 'With her daughter to care for and her abuela to help support, high school senior Emoni Santiago has to make the tough decisions, and do what must be done. The one place she can let her responsibilities go is in the kitchen, where she adds a little something magical to everything she cooks, turning her food into straight-up goodness.Still, she knows she doesn’t have enough \n  With her daughter to care for and her abuela to help support, high school senior Emoni Santiago has to make the tough decisions, and do what must be done. The one place she can let her responsibilities go is in the kitchen, where she adds a little something magical to everything she cooks, turning her food into straight-up goodness.Still, she knows she doesn’t have enough time for her school’s new culinary arts class, doesn’t have the money for the class’s trip to Spain—and shouldn’t still be dreaming of someday working in a real kitchen. But even with all the rules she has for her life—and all the rules everyone expects her to play by—once Emoni starts cooking, her only real choice is to let her talent break free.',
        pub_id: null,
        pub_date: '2019-05-07',
        serie_id: null,
        pages: 400,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/With_the_Fire_on_High.jpeg',
        edition: '1',
        average_rating: '4.42',
        authors: [
          {
            id: 1785,
            first_name: 'Elizabeth',
            last_name: 'Acevedo',
          },
        ],
      },
      {
        id: 7498,
        title: 'Five Feet Apart',
        description: 'Can you love someone you can never touch?Stella Grant likes to be in control—even though her totally out of control lungs have sent her in and out of the hospital most of her life. At this point, what Stella needs to control most is keeping herself away from anyone or anything that might pass along an infection and jeopardize the possibility of a lung transplant. Six feet apart. No exceptions.The only thing Will Newman wants to be in control of is getting out of this hospital. He couldn’t care less about his treatments, or a fancy new clinical drug trial. Soon, he’ll turn eighteen and then he’ll be able to unplug all these machines and actually go see the world, not just its hospitals.Will’s exactly what Stella needs to stay away from. If he so much as breathes on Stella she could lose her spot on the transplant list. Either one of them could die. The only way to stay alive is to stay apart. But suddenly six feet doesn’t feel like safety. It feels like punishment.What if they could steal back just a little bit of the space their broken lungs have stolen from them? Would five feet apart really be so dangerous if it stops their hearts from breaking too?',
        pub_id: null,
        pub_date: '2018-11-20',
        serie_id: null,
        pages: 288,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Five_Feet_Apart.jpeg',
        edition: '1',
        average_rating: '3.99',
        authors: [
          {
            id: 1933,
            first_name: 'Tobias',
            last_name: 'Iaconis',
          },
          {
            id: 1932,
            first_name: 'Rachael',
            last_name: 'Lippincott,',
          },
          {
            id: 1934,
            first_name: 'Mikki',
            last_name: 'Daughtry',
          },
          {
            id: 31074,
            first_name: 'Rachael',
            last_name: 'Lippincott',
          },
        ],
      },
      {
        id: 17158,
        title: 'The Grace Year',
        description: 'Survive the year. No one speaks of the grace year. It’s forbidden. In Garner County, girls are told they have the power to lure grown men from their beds, to drive women mad with jealousy. They believe their very skin emits a powerful aphrodisiac, the potent essence of youth, of a girl on the edge of womanhood. That’s why they’re banished for their sixteenth year, to release their magic into the wild so they can return purified and ready for marriage. But not all of them will make it home alive. Sixteen-year-old Tierney James dreams of a better life—a society that doesn’t pit friend against friend or woman against woman, but as her own grace year draws near, she quickly realizes that it’s not just the brutal elements they must fear. It’s not even the poachers in the woods, men who are waiting for a chance to grab one of the girls in order to make a fortune on the black market. Their greatest threat may very well be each other. With sharp prose and gritty realism, The Grace Year examines the complex and sometimes twisted relationships between girls, the women they eventually become, and the difficult decisions they make in-between.',
        pub_id: null,
        pub_date: '2019-10-08',
        serie_id: null,
        pages: 416,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Grace_Year.jpeg',
        edition: '1',
        average_rating: '3.98',
        authors: [
          {
            id: 8699,
            first_name: 'Kim',
            last_name: 'Liggett',
          },
        ],
      },
      {
        id: 8379,
        title: 'Opposite of Always',
        description: 'Jack Ellison King. King of Almost.He almost made valedictorian.He almost made varsity.He almost got the girl . . . When Jack and Kate meet at a party, bonding until sunrise over their mutual love of Froot Loops and their favorite flicks, Jack knows he’s falling—hard. Soon she’s meeting his best friends, Jillian and Franny, and Kate wins them over as easily as she did Jack. Jack’s curse of almost is finally over.But this love story is . . . complicated. It is an almost happily ever after. Because Kate dies. And their story should end there. Yet Kate’s death sends Jack back to the beginning, the moment they first meet, and Kate’s there again. Beautiful, radiant Kate. Healthy, happy, and charming as ever. Jack isn’t sure if he’s losing his mind. Still, if he has a chance to prevent Kate’s death, he’ll take it. Even if that means believing in time travel. However, Jack will learn that his actions are not without consequences. And when one choice turns deadly for someone else close to him, he has to figure out what he’s willing to do—and let go—to save the people he loves.',
        pub_id: null,
        pub_date: '2019-03-05',
        serie_id: null,
        pages: 464,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Opposite_of_Always.jpeg',
        edition: '1',
        average_rating: '3.92',
        authors: [
          {
            id: 2401,
            first_name: 'Justin A.',
            last_name: 'Reynolds',
          },
        ],
      },
      {
        id: 9997,
        title: 'Wilder Girls',
        description: "It's been eighteen months since the Raxter School for Girls was put under quarantine. Since the Tox hit and pulled Hetty's life out from under her.It started slow. First the teachers died one by one. Then it began to infect the students, turning their bodies strange and foreign. Now, cut off from the rest of the world and left to fend for themselves on their island home, t\n  It's been eighteen months since the Raxter School for Girls was put under quarantine. Since the Tox hit and pulled Hetty's life out from under her.It started slow. First the teachers died one by one. Then it began to infect the students, turning their bodies strange and foreign. Now, cut off from the rest of the world and left to fend for themselves on their island home, the girls don't dare wander outside the school's fence, where the Tox has made the woods wild and dangerous. They wait for the cure they were promised as the Tox seeps into everything.But when Byatt goes missing, Hetty will do anything to find her, even if it means breaking quarantine and braving the horrors that lie beyond the fence. And when she does, Hetty learns that there's more to their story, to their life at Raxter, than she could have ever thought true.",
        pub_id: null,
        pub_date: '2019-07-09',
        serie_id: null,
        pages: 357,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Wilder_Girls.jpeg',
        edition: '1',
        average_rating: '3.86',
        authors: [
          {
            id: 3046,
            first_name: 'Rory',
            last_name: 'Power',
          },
        ],
      },
      {
        id: 7235,
        title: 'On the Come Up',
        description: 'Sixteen-year-old Bri wants to be one of the greatest rappers of all time. Or at least make it out of her neighborhood one day. As the daughter of an underground rap legend who died before he hit big, Bri’s got big shoes to fill. But now that her mom has unexpectedly lost her job, food banks and shutoff notices are as much a part of Bri’s life as beats and rhymes. With bills piling up and homelessness staring her family down, Bri no longer just wants to make it—she has to make it.On the Come Up is Angie Thomas’s homage to hip-hop, the art that sparked her passion for storytelling and continues to inspire her to this day. It is the story of fighting for your dreams, even as the odds are stacked against you; of the struggle to become who you are and not who everyone expects you to be; and of the desperate realities of poor and working-class black families.',
        pub_id: null,
        pub_date: '2019-02-05',
        serie_id: null,
        pages: 464,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/On_the_Come_Up.jpeg',
        edition: '1',
        average_rating: '4.45',
        authors: [
          {
            id: 1022,
            first_name: 'Angie',
            last_name: 'Thomas',
          },
        ],
      },
      {
        id: 10473,
        title: 'Serpent & Dove (Serpent & Dove, #1)',
        description: "Bound as one to love, honor, or burn.Two years ago, Louise le Blanc fled her coven and took shelter in the city of Cesarine, forsaking all magic and living off whatever she could steal. There, witches like Lou are hunted. They are feared. And they are burned.Sworn to the Church as a Chasseur, Reid Diggory has lived his life by one principle: thou shalt not suffer a witch to live. His path was never meant to cross with Lou's, but a wicked stunt forces them into an impossible union—holy matrimony.The war between witches and Church is an ancient one, and Lou's most dangerous enemies bring a fate worse than fire. Unable to ignore her growing feelings, yet powerless to change what she is, a choice must be made.And love makes fools of us all.",
        pub_id: null,
        pub_date: '2019-09-03',
        serie_id: null,
        pages: 528,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Serpent___Dove__Serpent___Dove___1_.jpeg',
        edition: '1',
        average_rating: '3.98',
        authors: [
          {
            id: 3201,
            first_name: 'Shelby',
            last_name: 'Mahurin',
          },
        ],
      },
      {
        id: 8874,
        title: 'Love From A to Z',
        description: 'A marvel: something you find amazing. Even ordinary-amazing. Like potatoes—because they make French fries happen. Like the perfect fries Adam and his mom used to make together.An oddity: whatever gives you pause. Like the fact that there are hateful people in the world. Like Zayneb’s teacher, who won’t stop reminding the class how “bad” Muslims are.But Zayneb, the only Mus\n  A marvel: something you find amazing. Even ordinary-amazing. Like potatoes—because they make French fries happen. Like the perfect fries Adam and his mom used to make together.An oddity: whatever gives you pause. Like the fact that there are hateful people in the world. Like Zayneb’s teacher, who won’t stop reminding the class how “bad” Muslims are.But Zayneb, the only Muslim in class, isn’t bad. She’s angry.When she gets suspended for confronting her teacher, and he begins investigating her activist friends, Zayneb heads to her aunt’s house in Doha, Qatar, for an early start to spring break.Fueled by the guilt of getting her friends in trouble, she resolves to try out a newer, “nicer” version of herself in a place where no one knows her.Then her path crosses with Adam’s.Since he got diagnosed with multiple sclerosis in November, Adam’s stopped going to classes, intent, instead, on perfecting the making of things. Intent on keeping the memory of his mom alive for his little sister.Adam’s also intent on keeping his diagnosis a secret from his grieving father.Alone, Adam and Zayneb are playing roles for others, keeping their real thoughts locked away in their journals.Until a marvel and an oddity occurs…Marvel: Adam and Zayneb meeting.Oddity: Adam and Zayneb meeting.',
        pub_id: null,
        pub_date: '2019-04-30',
        serie_id: null,
        pages: 384,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Love_From_A_to_Z.jpeg',
        edition: '1',
        average_rating: '4.40',
        authors: [
          {
            id: 1432,
            first_name: 'S.K.',
            last_name: 'Ali',
          },
        ],
      },
      {
        id: 9749,
        title: 'The Rest of the Story',
        description: 'Emma Saylor doesn’t remember a lot about her mother, who died when she was ten. But she does remember the stories her mom told her about the big lake that went on forever, with cold, clear water and mossy trees at the edges.Now it’s just Emma and her dad, and life is good, if a little predictable…until Emma is unexpectedly sent to spend the summer with her mother’s family—her grandmother and cousins she hasn’t seen since she was a little girl.When Emma arrives at North Lake, she realizes there are actually two very different communities there. Her mother grew up in working class North Lake, while her dad spent summers in the wealthier Lake North resort. The more time Emma spends there, the more it starts to feel like she is divided into two people as well. To her father, she is Emma. But to her new family, she is Saylor, the name her mother always called her.Then there’s Roo, the boy who was her very best friend when she was little. Roo holds the key to her family’s history, and slowly, he helps her put the pieces together about her past. It’s hard not to get caught up in the magic of North Lake—and Saylor finds herself falling under Roo’s spell as well.For Saylor, it’s like a whole new world is opening up to her. But when it’s time to go back home, which side of her will win out?',
        pub_id: null,
        pub_date: '2019-06-04',
        serie_id: null,
        pages: 400,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Rest_of_the_Story.jpeg',
        edition: '1',
        average_rating: '4.03',
        authors: [
          {
            id: 1644,
            first_name: 'Sarah',
            last_name: 'Dessen',
          },
        ],
      },
      {
        id: 8381,
        title: 'Internment',
        description: "Rebellions are built on hope.Set in a horrifying near-future United States, seventeen-year-old Layla Amin and her parents are forced into an internment camp for Muslim American citizens.With the help of newly made friends also trapped within the internment camp, her boyfriend on the outside, and an unexpected alliance, Layla begins a journey to fight for freedom, leading a revolution against the internment camp's Director and his guards.Heart-racing and emotional, Internment challenges readers to fight complicit silence that exists in our society today.",
        pub_id: null,
        pub_date: '2019-03-19',
        serie_id: null,
        pages: 386,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Internment.jpeg',
        edition: '1',
        average_rating: '3.84',
        authors: [
          {
            id: 1428,
            first_name: 'Samira',
            last_name: 'Ahmed',
          },
        ],
      },
      {
        id: 27750,
        title: 'Lovely War',
        description: "A sweeping, multi-layered romance with a divine twist, by the Printz Honor-winning author of The Passion of Dolssa, set in the perilous days of World Wars I and II.It's 1917, and World War I is at its zenith when Hazel and James first catch sight of each other at a London party. She's a shy and talented pianist; he's a newly minted soldier with dreams of becoming an architect. When they fall in love, it's immediate and deep—and cut short when James is shipped off to the killing fields.Aubrey Edwards is also headed toward the trenches. A gifted musician who's played Carnegie Hall, he's a member of the 15th New York Infantry, an all-African-American regiment being sent to Europe to help end the Great War. Love is the last thing on his mind. But that's before he meets Colette Fournier, a Belgian chanteuse who's already survived unspeakable tragedy at the hands of the Germans.Thirty years after these four lovers' fates collide, the Greek goddess Aphrodite tells their stories to her husband, Hephaestus, and her lover, Ares, in a luxe Manhattan hotel room at the height of World War II. She seeks to answer the age-old question: Why are Love and War eternally drawn to one another? But her quest for a conclusion that will satisfy her jealous husband uncovers a multi-threaded tale of prejudice, trauma, and music and reveals that War is no match for the power of Love.",
        pub_id: null,
        pub_date: '2019-03-05',
        serie_id: null,
        pages: 480,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/Lovely_War.jpeg',
        edition: '1',
        average_rating: '4.55',
        authors: [
          {
            id: 19520,
            first_name: 'Julie',
            last_name: 'Berry',
          },
        ],
      },
      {
        id: 8598,
        title: 'The Vanishing Stair (Truly Devious, #2)',
        description: 'All Stevie Bell wanted was to find the key to the Ellingham mystery, but instead she found her classmate dead. And while she solved that murder, the crimes of the past are still waiting in the dark. Just as Stevie feels she’s on the cusp of putting it together, her parents pull her out of Ellingham academy.For her own safety they say. She must move past this obsession with crime. Now that Stevie’s away from the school of topiaries and secret tunnels, and her strange and endearing friends, she begins to feel disconnected from the rest of the world. At least she won’t have to see David anymore. David, who she kissed. David, who lied to her about his identity—son of despised politician Edward King. Then King himself arrives at her house to offer a deal: He will bring Stevie back to Ellingham immediately. In return, she must play nice with David. King is in the midst of a campaign and can’t afford his son stirring up trouble. If Stevie’s at school, David will stay put.The tantalizing riddles behind the Ellingham murders are still waiting to be unraveled, and Stevie knows she’s so close. But the path to the truth has more twists and turns than she can imagine—and moving forward involves hurting someone she cares for. In New York Times bestselling author Maureen Johnson’s second novel of the Truly Devious series, nothing is free, and someone will pay for the truth with their life.',
        pub_id: null,
        pub_date: '2019-01-22',
        serie_id: null,
        pages: 384,
        cover_photo: 'https://booksloth-book-assets.s3.amazonaws.com/books/The_Vanishing_Stair__Truly_Devious___2_.jpeg',
        edition: '1',
        average_rating: '4.25',
        authors: [
          {
            id: 346,
            first_name: 'Maureen',
            last_name: 'Johnson',
          },
        ],
      },
    ],
  },
];

export default discoverLists;
