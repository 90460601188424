import React, { useContext } from 'react';
import { ModalManager } from 'react-dynamic-modal';
import { AuthContext } from '../../../_contexts/AuthContext';
import { logEvent, actionEvent, locationEvent } from '../../../_utils/analytics';
// import EditProfile from '../../ProfilePage/EditProfile';
import DownloadModal from '../../DownloadModal';

const openEditProfileModal = (userId) => {
  // ModalManager.open(<EditProfile />);
  ModalManager.open(<DownloadModal onRequestClose={() => true} />);
  // Analytics
  logEvent(null, userId, actionEvent.editProfile, locationEvent.profile);
};

function EditProfileButton() {
  const { user } = useContext(AuthContext);
  return (
    <button
      type="button"
      className="btn btn-main profile-stats"
      data-bs-toggle="modal"
      data-bs-target="#profile-settings"
      onClick={() => openEditProfileModal(user.id)}
    >
      Edit Profile
    </button>
  );
}

export default EditProfileButton;
