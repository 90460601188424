/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../../../_contexts/AuthContext';
import { YearReviewContext } from '../../../../_contexts/YearReviewContext';
import { getUserTopBadges } from '../../../../_utils/yearinreview';
import './style.css';

const getBadges = async (userId, putBadges) => {
  const result = await getUserTopBadges(userId);
  if (result) {
    putBadges(result);
  }
};

const TopBadgesSection = () => {
  const { user } = useContext(AuthContext);
  const { badges, putBadges } = useContext(YearReviewContext);
  useEffect(() => {
    if (user.id && !badges) {
      getBadges(user.id, putBadges);
    }
  // eslint-disable-next-line
  }, [user.id]);

  if (badges && badges.length > 0) {
    return (
      <div className="top-badges">
        <div className="main-container">
          <h2>
            {user.firstName ?? user.email}
            's Top Badges
          </h2>
          <h3>
            Everyone has a unique reading taste and style, here are
            {' '}
            {user.firstName ?? user.email}
            's
            most used book badges.
          </h3>
          <div className="row">
            {badges.map((badge, index) => (
              <div className="col-xs-12 col-md-4" key={index}>
                <img className="fav-badge" src={badge.icon} />
                <div className="notification number">
                  {badge.badges_count}
                </div>
                <h2>{badge.name}</h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default TopBadgesSection;
