/* eslint-disable jsx-a11y/control-has-associated-label */
import './style.css';
import React, { useContext } from 'react';
import { ModalManager } from 'react-dynamic-modal';
import { AuthContext } from '../../../_contexts/AuthContext';
import { getDynamicLink } from '../../../_utils/yearinreview';
import { logEvent, actionEvent, locationEvent } from '../../../_utils/analytics';
import EditProfileButton from '../../Buttons/EditProfileButton';
import ShareProfile from '../../YearInReview/LoggedInStage/SharingSection';
import convertUnicode from '../../../_utils/emoji_converter';

const shareProfile = (link) => {
  ModalManager.open(
    <ShareProfile link={link} />,
  );
  // Analytics
  logEvent(null, null, actionEvent.shareProfile, locationEvent.profile);
};

const getLink = async (username, putDynamicLink) => {
  const result = await getDynamicLink(username);
  if (result) {
    putDynamicLink(result);
  }
};

function ProfileBio() {
  const { user } = useContext(AuthContext);
  const { dynamicLink, putDynamicLink } = useContext(AuthContext);
  if (user.username && !dynamicLink) {
    getLink(user.username, putDynamicLink);
  }
  if (user) {
    return (
      <div className="main-container">
        <div className="profile-container">
          <div className="profile-info row">
            <div className="col-sm-12 col-lg-2">
              <img className="user-photo" alt="reader" width="140" height="140" src={user.photo} />
            </div>
            <div className="col-sm-12 col-lg-10 profile-description">
              <h1>{convertUnicode(`${user.firstName} ${user.lastName}`)}</h1>
              <div className="row action-buttons">
                <div className="col-2">
                  <span>{user.booksRead}</span>
                  <p>Books</p>
                </div>
                <div className="col-2">
                  <span>{user.followers}</span>
                  <p>Followers</p>
                </div>
                <div className="col-2">
                  <span>{user.following}</span>
                  <p>Following</p>
                </div>
                <div className="col-sm-12 col-lg-6 action-buttons">
                  {/* <button type="button" className="btn btn-primary">Follow</button> */}
                  {(user.socialMedia.instagram ? (
                    <a
                      href={user.socialMedia.instagram}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/instagram_logo_purple.png" alt="instagram" />
                    </a>
                  )
                    : (<div />)
  )}
                  {(user.socialMedia.youtube ? (
                    <a
                      href={user.socialMedia.youtube}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/facebook_logo_purple.png" alt="facebook" />
                    </a>
                  )
                    : (<div />)
  )}
                  {(user.socialMedia.twitter ? (
                    <a
                      href={user.socialMedia.twitter}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/twitter_logo_purple.png" alt="twitter" />
                    </a>
                  )
                    : (<div />)
  )}
                  {(user.socialMedia.website ? (
                    <a
                      href={user.socialMedia.website}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/link_purple.png" alt="web" />
                    </a>
                  )
                    : (<div />)
  )}
                  <button type="button" onClick={() => shareProfile(dynamicLink)} className="profile-social share">
                    <img src="../a/i/share_icon_purple.png" alt="share" />
                  </button>
                  <EditProfileButton />
                </div>
              </div>
              <div className="profile-bio">
                <p>{convertUnicode(user ? user.bio : '')}</p>
              </div>
            </div>
            <div className="profile-actions" />
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default ProfileBio;
