import React, { useEffect, useContext } from 'react';
import { getUserStats } from '../../../../_utils/yearinreview';
import { AuthContext } from '../../../../_contexts/AuthContext';
import './style.css';
import { YearReviewContext } from '../../../../_contexts/YearReviewContext';

const getStats = async (userId, putStats) => {
  const result = await getUserStats(userId);
  if (result) {
    putStats(result);
  }
};

const UserBookStatistics = () => {
  const { user } = useContext(AuthContext);
  const { stats, putStats } = useContext(YearReviewContext);
  useEffect(() => {
    if (user.id && !stats) {
      getStats(user.id, putStats);
    }
    // eslint-disable-next-line
    }, [user.id]);
  if (stats) {
    return (
      <div className="UserBookStatistics-Section-Macro">
        <div className="UserBookStatistics-Section-Sub-Macro">
          <div className="UserBookStatistics-Section-Sub-Macro-Top">
            <div className="UserBookStatistics-Section-Header">
              {user.firstName ?? user.email}
              {' '}
              has read
              {' '}
              <br />
              <div className="stats-books-read">{stats.books_read ?? 0}</div>
              {' '}
              books.
            </div>
            <img className="UserBookStatistics-Section-Completed-Books" alt="reviews" src="./books-completed-glyph.svg" />
          </div>
          <div className="UserBookStatistics-Section-Sub-Macro-Bottom">
            <div className="UserBookStatistics-Section-Component">
              <img className="UserBookStatistics-Section-Image" alt="reviews" src="./pages-reviewed-glyph.svg" />
              <div className="UserBookStatistics-Section-Count">{stats.pages_read ?? 0}</div>
              <div className="UserBookStatistics-Section-Title">Pages Read</div>
            </div>
            <div className="UserBookStatistics-Section-Component">
              <img className="UserBookStatistics-Section-Image" alt="reviews" src="./books-reviewed-glyph.svg" />
              <div className="UserBookStatistics-Section-Count">{stats.reviews_count ?? 0}</div>
              <div className="UserBookStatistics-Section-Title">Books Reviewed</div>
            </div>
            <div className="UserBookStatistics-Section-Component">
              <img className="UserBookStatistics-Section-Image" alt="reviews" src="./badges-given-glyph.svg" />
              <div className="UserBookStatistics-Section-Count">{stats.badges_given}</div>
              <div className="UserBookStatistics-Section-Title">Badges Given</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default UserBookStatistics;
