/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useContext } from 'react';
import BookItem from './BookItem';
import { getUserTopAuthors } from '../../../../_utils/yearinreview';
import { AuthContext } from '../../../../_contexts/AuthContext';
import { YearReviewContext } from '../../../../_contexts/YearReviewContext';
import './style.css';

const getAuthors = async (userId, putAuthors) => {
  const result = await getUserTopAuthors(userId);
  if (result) {
    putAuthors(result);
  }
};

function TopAuthorsSection() {
  const { user } = useContext(AuthContext);
  const { authors, putAuthors } = useContext(YearReviewContext);
  useEffect(() => {
    if (user.id && !authors) {
      getAuthors(user.id, putAuthors);
    }
    // eslint-disable-next-line
	}, [user.id]);

  if (authors && authors.length > 0) {
    return (
      <div className="Top-Authors-Section-Macro">
        <div className="Top-Authors-Section-Sub-Macro">
          <div className="Top-Authors-Section-Micro-A">
            <div className="Top-Authors-Section-Header-Text">
              {user.firstName ?? user.email}
              's Top Authors
            </div>
            <div className="Top-Authors-Section-Caption-Text">
              <br />
              Here are
              {user.firstName ?? user.email}
              's most read authors on BookSloth.
            </div>
          </div>
          <div className="Top-Authors-Section-Micro-B">
            {authors.map((author, index) => (
              <BookItem item={author} key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default TopAuthorsSection;
