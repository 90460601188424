import BookSlothAPI from './api';

const actionEvent = {
  addBook: 'add_book',
  bookDetail: 'open_book_detail',
  buyBook: 'buy_book',
  createPost: 'create_post',
  editProfile: 'edit_profile',
  importBooks: 'import_books',
  leaveReview: 'leave_review',
  readingStats: 'reading_stats',
  readMore: 'read_more',
  shareProfile: 'share_profile',
};

const locationEvent = {
  profile: 'profile',
  discover: 'discover',
  bookDetails: 'book_details',
  community: 'community',
};

const log = (params) => {
  const api = new BookSlothAPI();
  api.logEvent(params);
};

/**
* Class init
* @param {bookId} bookId - bookId
* @param {userId} userId - userId
* @param {action} action - action
* @param {location} location - location
*/
const logEvent = (bookId, userId, action, location) => {
  const params = new URLSearchParams();
  params.append('name', 'WebApp Activity');
  params.append('text', bookId ? `book_id: ${bookId}` : '');
  params.append('type', 'web');
  params.append('userId', userId || 'web');
  params.append('action', action);
  params.append('location', location);

  log(params);
};

export {
  logEvent, actionEvent, locationEvent,
};
