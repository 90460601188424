/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useContext } from 'react';
import BookItem from '../TopAuthorsSection/BookItem';
import { AuthContext } from '../../../../_contexts/AuthContext';
import { YearReviewContext } from '../../../../_contexts/YearReviewContext';
import { getUserTopBooks } from '../../../../_utils/yearinreview';
import './style.css';

const getBooks = async (userId, putBooks) => {
  const result = await getUserTopBooks(userId);
  if (result) {
    putBooks(result);
  }
};

const TopBooksSection = () => {
  const { user } = useContext(AuthContext);
  const { books, putBooks } = useContext(YearReviewContext);
  useEffect(() => {
    if (user.id && !books) {
      getBooks(user.id, putBooks);
    }
  // eslint-disable-next-line
  }, [user.id]);

  if (books && books.length > 0) {
    return (
      <div className="Top-Books-Section-Macro">
        <div className="Top-Books-Section-Sub-Macro">
          <div className="Top-Books-Section-Micro-A">
            <div className="Top-Books-Section-Header-Text">
              {user.firstName ?? user.email}
              's Top
              {' '}
              {books.length}
              {' '}
              books
            </div>
            <div className="Top-Books-Section-Caption-Text">
              {/* We know how hard it is to narrow down your favorite books.
              <br />
              {' '}
              Take a moment to look at the books
              {user.firstName ?? user.email}
              {' '}
              loved the most. */}
            </div>
          </div>
          <div className="Top-Books-Section-Micro-B">
            {books.map((book, index) => (
              <BookItem item={book} key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default TopBooksSection;
