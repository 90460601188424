/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from 'react';
import { AuthContext } from '../../../_contexts/AuthContext';
import convertUnicode from '../../../_utils/emoji_converter';
import '../../ProfilePage/ProfileBio/style.css';

function PublicProfileBio() {
  const { publicUser } = useContext(AuthContext);
  if (publicUser) {
    return (
      <div className="main-container">
        <div className="profile-container">
          <div className="profile-info row">
            <div className="col-sm-12 col-lg-2">
              <img className="user-photo" alt="reader" width="140" height="140" src={publicUser.photo} />
            </div>
            <div className="col-sm-12 col-lg-10 profile-description">
              <h1>{convertUnicode(`${publicUser.firstName} ${publicUser.lastName}`)}</h1>
              <div className="row action-buttons">
                <div className="col-2">
                  <span>{publicUser.booksRead}</span>
                  <p>Books</p>
                </div>
                <div className="col-2">
                  <span>{publicUser.followers}</span>
                  <p>Followers</p>
                </div>
                <div className="col-2">
                  <span>{publicUser.following}</span>
                  <p>Following</p>
                </div>
                <div className="col-sm-12 col-lg-6 action-buttons">
                  {/* <button type="button" className="btn btn-primary">Follow</button> */}
                  {(publicUser.socialMedia.instagram ? (
                    <a
                      href={publicUser.socialMedia.instagram}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/instagram_logo_purple.png" alt="instagram" />
                    </a>
                  )
                    : (<div />)
  )}
                  {(publicUser.socialMedia.youtube ? (
                    <a
                      href={publicUser.socialMedia.youtube}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/facebook_logo_purple.png" alt="facebook" />
                    </a>
                  )
                    : (<div />)
  )}
                  {(publicUser.socialMedia.twitter ? (
                    <a
                      href={publicUser.socialMedia.twitter}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/twitter_logo_purple.png" alt="twitter" />
                    </a>
                  )
                    : (<div />)
  )}
                  {(publicUser.socialMedia.website ? (
                    <a
                      href={publicUser.socialMedia.website}
                      className="profile-social"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="../a/i/link_purple.png" alt="web" />
                    </a>
                  )
                    : (<div />)
  )}
                </div>
              </div>
              <div className="profile-bio">
                <p>{convertUnicode(publicUser.bio)}</p>
              </div>
              <div className="profile-actions" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

export default PublicProfileBio;
