/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import BookRating from '../BookRating';
import './style.css';

function BookItem(props) {
  const { book } = props;

  return (
    <div className="discover book-item">
      <a href={`/book/${book ? book.id : ''}`}>
        <div className="discover-image-height">
          <img className="book-cover-image" alt="books" src={book ? book.cover_photo : 'https://s3.amazonaws.com/booksloth-book-assets/placeholder.png'} />
        </div>
        <p className="book-title">{book ? book.title : ''}</p>
        <div className="book-author-names">
          {book.authors.map((author) => (
            <p className="book-author">
              {author.first_name}
              {' '}
              {author.last_name}
            </p>
          ))}
        </div>
        <BookRating starRating={book ? book.average_rating : ''} />
      </a>
    </div>
  );
}

export default BookItem;
