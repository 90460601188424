/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import convertUnicode from '../../../_utils/emoji_converter';
import Reply from '../Reply';

function PostItem(props) {
  const { post } = props;
  if (post) {
    return (
      <div className="thread-container">
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={post.id}>
              <div className="post-container">
                <div className="main-post-photo">
                  <a href={`/reader/${post.username}`}>
                    <img className="user-photo" alt="reader" width="60" height="60" src={post.photo ? post.photo : 'https://booksloth-profile-assets.s3.amazonaws.com/profile_placeholder_3.jpeg'} />
                  </a>
                </div>
                <div className="main-post-text">
                  <h1 className="user-name">{convertUnicode(post.first_name)}</h1>
                  <p className="user-main-post">{convertUnicode(post.message)}</p>
                  <div className="main-post-actions">
                    {post.replies && post.replies.length > 0
                      ? (
                        <p className="main-post-reply" data-bs-toggle="modal" data-bs-target="#reply-post">
                          <img className="post-action" alt="reply" width="20" height="20" src="../a/i/c-reply.png" />
                          {post.replies.length}
                        </p>
                      ) : (null)}
                    <p className="main-post-type">
                      #
                      {post.type}
                    </p>
                    {post.replies && post.replies.length > 0
                      ? (
                        <button className="post-expand">
                          <img className="post-action" alt="post" width="15" height="20" src="../a/i/c-chevron.png" />
                        </button>
                      ) : (null)}
                  </div>
                </div>
              </div>
            </Accordion.Toggle>
            {post.replies.map((reply) => (
              <Accordion.Collapse eventKey={post.id}>
                <Reply item={reply} key={reply.id} />
              </Accordion.Collapse>
            ))}
          </Card>
        </Accordion>
      </div>
    );
  }
  return null;
}

export default PostItem;
