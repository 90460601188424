import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import './style.css';

const AppBanner = () => {
  // const myRef = useRef(null);
  // const executeScroll = () => myRef.current.scrollIntoView();

  return (
    <div className="banner-container">
      <div className="main-container">
        <div className="banner-action-button">
          <h2>For the full BookSloth experience download our app.</h2>
          {isAndroid || isIOS ? (
            <a href="https://booksloth.page.link/web">
              <button type="button" className="btn btn-primary">Open in App</button>
            </a>
          )
            : null}
        </div>
      </div>
    </div>
  );
};

export default AppBanner;
