/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { createContext, useState } from 'react';

export const CommunityContext = createContext();

require('dotenv').config();

const postPerPage = process.env.REACT_APP_POSTS_PER_PAGE;

const CommunityContextProvider = (props) => {
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState('all');
  const [posts, setPosts] = useState();
  const putCategory = (category) => {
    setCategory(category);
  };
  const putReplies = (replies) => {
    const items = [];
    replies.map((reply) => (
      items.push({
        id: reply.id,
        message: reply.message,
        updated_at: reply.updated_at,
        user_id: reply.user_id,
        first_name: reply.first_name,
        last_name: reply.last_name,
        email: reply.email,
        username: reply.username,
        photo: reply.photo,
      })
    ));
    return items;
  };
  const putPosts = (postList, prevPosts) => {
    const items = prevPosts ? prevPosts.posts : [];
    // if (sessionStorage.posts) {
    //   items = JSON.parse(sessionStorage.posts);
    // }
    postList.map((post) => (
      items.push({
        id: post.id,
        message: post.message,
        type: post.type,
        updated_at: post.updated_at,
        user_id: post.user_id,
        first_name: post.first_name,
        last_name: post.last_name,
        username: post.username,
        email: post.email,
        photo: post.photo,
        replies: post.replies !== undefined ? putReplies(post.replies) : null,
      })
    ));
    setPosts(items);
    // sessionStorage.posts = JSON.stringify(items);
    const page = items.length / postPerPage;
    setPage(page + 1);
  };

  return (
    <CommunityContext.Provider value={{
      page,
      posts,
      category,
      setCategory,
      setPage,
      setPosts,
      putPosts,
      putCategory,
    }}
    >
      {props.children}
    </CommunityContext.Provider>
  );
};

export default CommunityContextProvider;
