/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useRef } from 'react';
import { Modal, Effect } from 'react-dynamic-modal';
import {
  FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon,
} from 'react-share';

const SharingSection = (props) => {
  const dynamicLink = props;
  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
  }

  if (dynamicLink.link) {
    return (
      <Modal onRequestClose={false} effect={Effect.ScaleUp}>
        <div className="share-friends">
          <div className="main-container">
            <h1>Share with friends!</h1>
            <h2>There's no doubt your reading year has been amazing, let your friends know about it!</h2>
            <div className="share-profile-url">
              <textarea
                ref={textAreaRef}
                value={`${dynamicLink.link}`}
              />
              <button type="button" onClick={copyToClipboard}>Copy Link</button>
              {copySuccess}
            </div>
            <div className="social-media">
              <FacebookShareButton
                url={`${dynamicLink.link}`}
                quote="My BookSloth Profile"
                hashtag="#booksloth"
              >
                <FacebookIcon />
              </FacebookShareButton>
              <TwitterShareButton
                url={`${dynamicLink.link}`}
                quote="My BookSloth Profile"
                hashtag="#booksloth"
              >
                <TwitterIcon />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

export default SharingSection;
