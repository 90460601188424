/* eslint-disable no-tabs */
import React from 'react';
import UserStatisticsSection from './UserStatisticsSection';
import GraphsSection from './GraphsSection';
import TopBooksSection from './TopBooksSection';
import TopGenresSection from './TopGenresSection';
import TopAuthorsSection from './TopAuthorsSection';
import TopBadgesSection from './TopBadgesSection';

const ReadingStats = () => {
  return (
    <div>
      <UserStatisticsSection />
      <GraphsSection />
      <TopBooksSection />
      <TopGenresSection />
      <TopAuthorsSection />
      <TopBadgesSection />
    </div>
  );
};

export default ReadingStats;
