/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { createContext, useState } from 'react';

export const YearReviewContext = createContext();

const YearReviewContextProvider = (props) => {
  const [stats, setStats] = useState();
  const [authors, setAuthors] = useState();
  const [badges, setBadges] = useState();
  const [badgesGraph, setBadgesGraph] = useState();
  const [books, setBooks] = useState();
  const [dynamicLink, setDynamicLink] = useState();
  const [genres, setGenres] = useState();
  const [genresGraph, setGenresGraph] = useState();
  const [pagesGraph, setPagesGraph] = useState();
  const [ratingsGraph, setRatingsGraph] = useState();
  const customcolors = [
    '#8D57D4', '#673888', '#ef4f91',
    '#1ebbd7', '#189ad3', '#8765F7'];
  const putStats = (stats) => {
    setStats(stats);
  };
  const putAuthors = (authors) => {
    const items = [];
    authors.map((author) => (
      items.push({
        header: `${author.first_name} ${author.last_name}`,
        caption: '',
        cover_photo: author.cover_photo,
      })
    ));
    setAuthors(items);
  };
  const putBadges = (badges) => {
    setBadges(badges);
  };
  const putBadgesGraph = (badges) => {
    const items = [];
    badges.map((badge, index) => (
      items.push({
        title: badge.name,
        value: badge.badges_count,
        color: customcolors[index],
      })
    ));
    setBadgesGraph(items);
  };
  const putPagesGraph = (pages) => {
    const items = [];
    pages.map((page, index) => (
      items.push({
        title: page.page_range,
        value: page.count,
        color: customcolors[index],
      })
    ));
    setPagesGraph(items);
  };
  const putRatingsGraph = (ratings) => {
    const items = [];
    ratings.map((rating, index) => (
      items.push({
        title: rating.rating,
        value: rating.count,
        color: customcolors[index],
      })
    ));
    setRatingsGraph(items);
  };
  const putBooks = (books) => {
    const items = [];
    books.map((book) => (
      items.push({
        header: book.title,
        caption: book.title,
        cover_photo: book.cover_photo,
      })
    ));
    setBooks(items);
  };
  const putDynamicLink = (link) => {
    setDynamicLink(link);
  };
  const putGenres = (genres) => {
    setGenres(genres);
  };
  const putGenresGraph = (genres) => {
    const items = [];
    genres.map((genre, index) => (
      items.push({
        title: genre.name,
        value: genre.genres_count,
        color: customcolors[index],
      })
    ));
    setGenresGraph(items);
  };
  return (
    <YearReviewContext.Provider value={{
      authors,
      badges,
      badgesGraph,
      books,
      dynamicLink,
      genres,
      genresGraph,
      pagesGraph,
      ratingsGraph,
      stats,
      putAuthors,
      putBadges,
      putBadgesGraph,
      putBooks,
      putDynamicLink,
      putGenres,
      putGenresGraph,
      putPagesGraph,
      putRatingsGraph,
      putStats,
    }}
    >
      {props.children}
    </YearReviewContext.Provider>
  );
};

export default YearReviewContextProvider;
