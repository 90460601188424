/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-shadow */
import React, { createContext, useState } from 'react';

export const BookContext = createContext();

const BookContextProvider = (props) => {
  const [book, setBook] = useState();
  const [bookComments, setBookComments] = useState(null);
  const [bookElements, setBookElements] = useState(null);
  const putBookElements = (elements) => {
    const items = [];
    elements.map((element) => (
      items.push({
        count: element.count,
        id: element.BookBadge.id,
        name: element.BookBadge.name,
        description: element.BookBadge.description,
        icon: element.BookBadge.icon,
        icon_bw: element.BookBadge.icon_bw,
      })
    ));
    setBookElements(items);
  };
  const putBookComments = (comments) => {
    const items = [];
    comments.map((comment) => (
      items.push({
        id: comment.id,
        review: comment.review || '',
        star_rating: comment.star_rating,
        user_id: comment.User.id,
        first_name: comment.User.first_name,
        last_name: comment.User.last_name,
        username: comment.User.username,
        photo: comment.User.photo,
        email: comment.User.email,
      })
    ));
    setBookComments(items);
  };
  return (
    <BookContext.Provider value={{
      book,
      bookComments,
      bookElements,
      setBook,
      setBookComments,
      setBookElements,
      putBookElements,
      putBookComments,
    }}
    >
      {props.children}
    </BookContext.Provider>
  );
};

export default BookContextProvider;
